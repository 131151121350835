import buildSlugParams from '../../../helpers/buildSlugParams';

const onSubmit = ({
  onBeforeSubmit,
  arriving,
  leaving,
  isMonthly,
  monthlyDuration,
  listingId,
  monthlyType,
  navigate
}) => {
  onBeforeSubmit();

  const buildParams = buildSlugParams(
    {
      arriving,
      leaving
    },
    isMonthly,
    monthlyDuration,
    monthlyType
  );
  const searchParams = buildParams();

  const url = `/checkout/${listingId}/?${searchParams}`;

  navigate(url);
};

export default onSubmit;
