import React from 'react';
import Badge from '@justpark/ui/src/components/Badge';
import Typography from '@justpark/ui/src/components/Typography';
import { useTranslation } from 'react-i18next';
import HrefButton from '@justpark/ui/src/components/HrefButton';
import AppIcons from '../Common/AppIcons';

import styles from '../../../static/scss/components/listingDetails/driveUpBanner.module.scss';

const DriveUpBannerContent = ({
  locationId,
  seasonTicketLink
}: {
  locationId: number;
  seasonTicketLink: string | null;
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.bannerContent}>
      <Badge>
        <div className={styles.pin}>
          <Typography className={styles.pinTitle} bold>
            {t('listingDetails:locationId', 'Location ID')}
          </Typography>
          <Typography bold color="brand">
            <span className={styles.locationId} data-cy="location-id">
              {locationId}
            </span>
          </Typography>
        </div>
      </Badge>
      <Typography className={styles.description}>
        {t(
          'listingDetails:driveUpMessage',
          'No advanced bookings. Pay for parking using JustPark at this location. Please download the JustPark app for iOS or Android.'
        )}
      </Typography>
      <AppIcons
        theme="dark"
        locationTrackEvent="listingdetails_driveup"
        baseClassName={styles.appDownloadList}
      />

      {seasonTicketLink && (
        <div className={styles.btnContainer}>
          <HrefButton
            cypress="permits-tickets-cta"
            href={seasonTicketLink}
            block
          >
            {t('listingDetails:permitsAndTickets', 'Permit & Tickets')}
          </HrefButton>
        </div>
      )}
    </div>
  );
};

export default DriveUpBannerContent;
