// @ts-nocheck
import styles from "../components/Icons/iconSizes.module.scss";
import type { IconSizes } from "../types/Icons";

const getSizeClass = (size: IconSizes) => {
  if (size === "0.75x") {
    return styles.sizeThreeQuarters;
  }
  if (size === "1x") {
    return styles.size1x;
  }
  if (size === "1.5x") {
    return styles.size1Half;
  }
  if (size === "1.25x") {
    return styles.size1Quarter;
  }
  return styles.size2x;
};
export default getSizeClass;
