import { useTranslation } from 'react-i18next';
import PlaneUp from '@justpark/ui/src/components/FontAwesome/PlaneUp';
import Typography from '@justpark/ui/src/components/Typography';
import React from 'react';

import styles from '../../../static/scss/components/search/trustedAirport.module.scss';

const TrustedAirport = () => {
  const { t } = useTranslation();

  return (
    <div data-cy="trusted-airport" className={styles.container}>
      <PlaneUp size={16} className={styles.icon} />
      <Typography className={styles.text} color="primary" size="secondary">
        {t('search:trustedAirport', 'Trusted airport parking')}
      </Typography>
    </div>
  );
};

export default TrustedAirport;
