// @ts-nocheck
import React from "react";
import roundToLowest100 from "@justpark/helpers/src/roundToLowest100/roundToLowest100";
import MinimalReviewStars from "../MinimalReviewStars";
import Typography from "../Typography";
import SparkleIcon from "../qicons/SparkleIcon";
import styles from "./ratingWithBookingCount.module.scss";

type T = (
  b: string,
  a:
    | string
    | {
        [x: string]: string;
      }
) => string;
type Props = {
  rating: number;
  bookingCount: number;
  t: T;
};
const RatingWithBookingCount = ({ rating, bookingCount, t }: Props) => {
  const count = roundToLowest100(bookingCount);
  const isRounded = count !== bookingCount;
  const plus = isRounded ? "+" : "";
  if (rating === 0) {
    return (
      <div className={styles.base} data-cy="just-added">
        <SparkleIcon size="1x" />
        <Typography color="muted" size="secondary">
          <div className={styles.text}>
            {t("ui:ratingWithBooking-new", "Just added")}
          </div>
        </Typography>
      </div>
    );
  }
  return (
    <div className={styles.base}>
      <MinimalReviewStars rating={rating} />
      {count > 0 ? (
        <Typography color="muted" size="secondary">
          <div className={styles.text}>
            {t(
              "ui:ratingWithBooking-bookings",
              {
                count,
                plus
              },
              `${count}${plus} Bookings`
            )}
          </div>
        </Typography>
      ) : null}
    </div>
  );
};
export default RatingWithBookingCount;
