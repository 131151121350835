// @ts-nocheck
import React from "react";
import RugbySvg from "../../../svg/rugby.svg";
import "./rugby.scss";

const Rugby = () => {
  const baseClass = "c-rugby";
  return <RugbySvg className={baseClass} />;
};
export default Rugby;
