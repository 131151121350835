import React from 'react';
import Typography from '@justpark/ui/src/components/Typography';
import ConnectedBookWithConfidence from '../ConnectedBookWithConfidence';

import styles from '../../../static/scss/components/listingDetails/bookingPanel.module.scss';

type Props = {
  children: React.ReactNode;
  t: (b: string, a: string) => string;
};

const BookingPanel = ({ t, children }: Props) => (
  <div className={styles.bookingPanel} data-cy="booking-panel">
    <Typography size="h2" bold className={styles.heading}>
      <div>{t('listingDetails:bookHeader', 'Reserve your parking space')}</div>
    </Typography>

    {children}

    <div className={styles.trustContainer} data-cy="listing-details-trust">
      <ConnectedBookWithConfidence isBold />
    </div>
  </div>
);

export default BookingPanel;
