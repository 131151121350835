// eslint-disable
// @ts-nocheck
import React from "react";
import dayjs from "dayjs";
import RightChevronIcon from "../Icons/RightChevronIcon";
import Typography from "../Typography";
import ToolTipNativeModal from "../ToolTipNativeModal";
import AvailabilityStatus, { StatusIndicator } from "../AvailablityStatus";
import useShouldEnableNative from "../../hooks/useShouldEnableNative";
import calendarDays from "../../helpers/calendarDays";
import styles from "./calendarGrid.module.scss";

type T = (b: string, a: string) => string;
type Status =
  | "AVAILABLE"
  | "NOT_AVAILABLE"
  | "PARTIAL"
  | "FULLY_BOOKED"
  | "PAST";
type Availability = {
  startTime: string;
  endTime: string;
};
export type DataItem = {
  status: Status;
  availability: Availability[];
  day: number;
  totalBookings: number;
};
type actionType = "prev" | "next";
type CalendarGridProps = {
  firstDayOfMonth: number;
  data: Array<DataItem>;
  title: string;
  onMonthChange: (state: actionType) => void;
  month: string | number;
  year: string | number;
  t: T;
  containerClassName?: string;
  disablePrevBtn?: boolean;
  disableNextBtn?: boolean;
  cypress?: string;
  loading: boolean;
};
type HeaderProps = {
  title: string;
  onMonthChange: (state: actionType) => void;
  disablePrevBtn: boolean;
  disableNextBtn: boolean;
};
type ItemProps = {
  data: Array<DataItem>;
  firstDayOfMonth: number;
  month: string | number;
  year: string | number;
  t: T;
};
const weekdays = calendarDays();
const getStatusClassName = (status: Status) => {
  switch (status) {
    case "AVAILABLE":
      return styles.available;
    case "PARTIAL":
      return styles.partially;
    case "FULLY_BOOKED":
      return styles.booked;
    case "NOT_AVAILABLE":
      return styles.notAvailable;
    case "PAST":
      return styles.past;
    default:
      return "";
  }
};
const CalendarMonthHeader = ({
  title,
  onMonthChange,
  disablePrevBtn,
  disableNextBtn
}: HeaderProps) => {
  return (
    <div className={styles.month}>
      <button
        className={`${styles.prev} ${disablePrevBtn && styles.disabled}`}
        onClick={() => onMonthChange("prev")}
        type="button"
        disabled={disablePrevBtn}
        data-cy="calendar-prev-button"
      >
        <RightChevronIcon />
      </button>
      <Typography size="secondary" bold>
        <span data-cy="calendar-title">{title}</span>
      </Typography>
      <button
        className={`${styles.next} ${disableNextBtn && styles.disabled}`}
        onClick={() => onMonthChange("next")}
        type="button"
        disabled={disableNextBtn}
        data-cy="calendar-next-button"
      >
        <RightChevronIcon />
      </button>
    </div>
  );
};
const content = (
  message: string | Availability[],
  status: Status,
  t: T,
  useNative: boolean
) => {
  const isPartial = status === "PARTIAL";
  if (typeof message === "string") {
    return (
      <div className={styles.flexCol}>
        {!useNative || isPartial ? (
          <span className={styles.info}>{message}</span>
        ) : (
          <StatusIndicator
            message={message}
            status={status}
            messageClassName={styles.statusText}
            modifierClassName={styles.status}
          />
        )}
      </div>
    );
  }
  const getTranslationValue = (availability) => {
    if (availability.length > 1) {
      const startTimeFirst = availability[0].startTime;
      const endTimeFirst = availability[0].endTime;
      const startTimeSecond = availability[1].startTime;
      const endTimeSecond = message[1].endTime;
      return t(
        "listingPageCalendar:bookedFromMultiple",
        `Unavailable between ${startTimeFirst} - ${endTimeFirst} & ${startTimeSecond} - ${endTimeSecond}`,
        {
          startTimeFirst,
          endTimeFirst,
          startTimeSecond,
          endTimeSecond
        }
      );
    }
    const { startTime, endTime } = availability[0];
    return t(
      "listingPageCalendar:bookedFromSingle",
      `Unavailable between ${startTime} and ${endTime}`,
      {
        startTime,
        endTime
      }
    );
  };
  return (
    <div>
      {!useNative ? (
        <span className={styles.info}>{getTranslationValue(message)}</span>
      ) : (
        <StatusIndicator
          message={getTranslationValue(message)}
          status={status}
          messageClassName={styles.statusText}
          modifierClassName={styles.status}
        />
      )}
    </div>
  );
};
const CalendarItem = ({ data, firstDayOfMonth, month, year, t }: ItemProps) => {
  const style = {
    gridColumnStart: firstDayOfMonth === 0 ? 7 : firstDayOfMonth
  };
  const now = dayjs();
  const currentDay = now.date();
  const currentMonth = dayjs().format("MM");
  const useNative = useShouldEnableNative();
  const getStylesAndMessage = (item: DataItem) => {
    const { status, availability, totalBookings } = item;
    const statusClassname = getStatusClassName(status);
    switch (status) {
      case "AVAILABLE":
        return {
          style: `${styles.item} ${statusClassname}`,
          infoMessage: t("listingPageCalendar:available", "Fully available")
        };
      case "PARTIAL":
        return {
          style: `${styles.item} ${statusClassname}`,
          infoMessage:
            totalBookings >= 3
              ? t(
                  "listingPageCalendar:partial",
                  "This day already has multiple bookings. For improved accuracy select your desired time and attempt to place a booking."
                )
              : availability
        };
      case "NOT_AVAILABLE":
        return {
          style: `${styles.item} ${statusClassname}`,
          infoMessage: t("listingPageCalendar:unavailable", "Unavailable")
        };
      case "FULLY_BOOKED":
        return {
          style: `${styles.item} ${statusClassname}`,
          infoMessage: t("listingPageCalendar:fullyBooked", "Fully booked")
        };
      default:
        return {
          style: `${styles.item} ${statusClassname}`,
          infoMessage: ""
        };
    }
  };
  return (
    <div className={`${styles.days} ${styles.grid}`}>
      {data.map((item, i) => {
        const { style: cssStyle, infoMessage } = getStylesAndMessage(item);
        const infoContent = content(infoMessage, item.status, t, useNative);
        if (item.status === "PAST") {
          return (
            <div
              key={`${item.day}`}
              className={`${cssStyle} ${
                item.day === currentDay && currentMonth === month
                  ? styles.today
                  : ""
              }`}
              style={i === 0 ? style : {}}
            >
              {item.day}
            </div>
          );
        }

        return (
          <div
            key={`${item.day}`}
            className={`${cssStyle} ${
              item.day === currentDay && currentMonth === month
                ? styles.today
                : ""
            }`}
            style={i === 0 ? style : {}}
          >
            <ToolTipNativeModal
              modalId={`${item.day}`}
              content={infoContent}
              theme="jp-web-navy-blue"
              maxWidth={262}
              position="bottom"
              smallHeader
              contentTitle={`${t(
                "dateFormat:nthDate",
                "{{date,Do [of] MMMM YYYY }}",
                {
                  date: dayjs(`${year}-${month}-${item.day}`, "YYYY-MM-D")
                }
              )}`}
              modalHeadingClassName={styles.modalHeading}
              modalCloseButtonClassName={styles.modalCloseBtn}
              key={`${item.day}`}
            >
              <div className={styles.inner}>{item.day}</div>
            </ToolTipNativeModal>
          </div>
        );
      })}
    </div>
  );
};
const CalendarWeekDays = () => {
  return (
    <div className={styles.grid}>
      {weekdays.map((weekday) => {
        return (
          <Typography key={weekday} className={styles.weekday} color="light">
            <span data-cy="calendar-weekday">{weekday}</span>
          </Typography>
        );
      })}
    </div>
  );
};

const CalendarGrid = ({
  data,
  title,
  firstDayOfMonth,
  onMonthChange,
  month,
  year,
  t,
  containerClassName = "",
  disablePrevBtn = false,
  disableNextBtn = false,
  cypress = "",
  loading
}: CalendarGridProps) => {
  return (
    <div
      className={`${styles.calendarContainer} ${containerClassName} ${
        loading ? styles.loading : ""
      }`}
      data-cy={cypress}
    >
      <CalendarMonthHeader
        title={title}
        onMonthChange={onMonthChange}
        disablePrevBtn={disablePrevBtn}
        disableNextBtn={disableNextBtn}
      />
      <CalendarWeekDays />
      <CalendarItem
        data={data}
        firstDayOfMonth={firstDayOfMonth}
        month={month}
        year={year}
        t={t}
      />
      <div className={styles.availabilityStatus}>
        <AvailabilityStatus t={t} />
      </div>
    </div>
  );
};

export default CalendarGrid;
