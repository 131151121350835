// @ts-nocheck
// eslint-disable-next-line
import React, { cloneElement, useState } from "react";
import bem from "@justpark/helpers/src/bem/bem";
import type { Element } from "react";
import Error from "../Error";
import Typography from "../Typography";
import kebabCase from "../../helpers/kebab-case";
import CrossIcon from "../Icons/CrossIcon";
import ClearIcon from "../Icons/ClearIcon";
import WarningIcon from "../Icons/WarningIcon";
import "./inputBox.scss";

type Props = {
  /**
   * The placeholder text.
   *
   * This SHOULD be a hint about the data to be entered. Remember that
   * placeholder text is generally hidden from accessibility tools and will NOT
   * be translated! If no label is given, this will be used as the accessibility
   * label.
   */
  placeholder?: string | null;
  /**
   * The error message to show about the input.
   *
   * Setting this will cause the error icon to show and a red bar below the the
   * input.
   */
  error?: string;
  /**
   * The label for the input.
   *
   * This should describe the input. It can be hidden using the showLabel prop,
   * but will still be accessible to ATs :) If you don't set a label, the
   * placeholder text will be used as the accessibility label.
   */
  label?: string;
  /**
   * An identifier which must be unique in the view.
   *
   * This prop allows the assistive techs to associate error information with
   * the input.
   */
  id?: string;
  /**
   * If falsey, hides the label.
   *
   * If the label is hidden, the input will get an ARIA label attribute instead
   * of a label element.
   */
  showLabel?: boolean;
  /**
   * An icon to render.
   *
   * This is not related to the error icon which will show when the error prop
   * is set
   */
  icon?: Element<any> | null;
  iconPosition?: "left" | "right";
  disabled?: boolean;
  value?: string;
  onChange?: (a: string) => void;
  type?: "text" | "number" | "password" | "date" | "time" | "email";
  warning?: boolean;
  className?: string;
  showClearButton?: boolean;
  autoComplete?: string;
  onKeyDown?: (event: SyntheticKeyboardEvent<any>) => any;
  onClick?: () => void;
  cypress?: string | null;
  name?: string;
  onFocus?: () => void;
  onBlur?: () => void;
  inputRef?: any;
  inputMode?: "text" | "numeric";
  secondaryLabel?: string;
  hideErrorCross?: boolean;
  noBottomRadius?: boolean;
  required?: boolean;
  bold?: boolean;
};
/**
 * Primary Input Box
 */
const InputBox = ({
  placeholder = "",
  error = "",
  label = null,
  secondaryLabel = "",
  id = "",
  showLabel = true,
  icon = null,
  iconPosition = "left",
  disabled = false,
  value = "",
  onChange = () => {},
  type = "text",
  warning = false,
  className = "",
  autoComplete = "",
  showClearButton = false,
  onKeyDown = () => {},
  onClick = () => {},
  cypress = null,
  name = "",
  onFocus = () => {},
  onBlur = () => {},
  inputRef = null,
  inputMode = "text",
  hideErrorCross = false,
  noBottomRadius = false,
  required = false,
  bold = false
}: Props) => {
  const baseClass = "c-input";
  const hasError = !!error;
  const inputClasses = bem(baseClass, "inputbox", {
    error: hasError,
    disabled,
    icon: !!icon,
    warning,
    noBottomRadius,
    bold
  });
  const inputWrapperClasses = bem(baseClass, "inputwrapper", {
    error: hasError,
    iconRight: !!icon && iconPosition === "right",
    iconLeft: !!icon && iconPosition === "left"
  });
  const useableLabel = label || placeholder;
  const [errorID] = useState(`${kebabCase(id || useableLabel)}-error`);
  const errorComponent = error.trim() ? (
    <div aria-live="polite">
      <Error error={error} />
    </div>
  ) : null;
  const inputIcon = icon
    ? cloneElement(icon, {
        className: icon.props.className
          ? `${icon.props.className} ${baseClass}__icon`
          : `${baseClass}__icon`,
        role: icon.props.role || "presentation"
      })
    : null;
  const inputWrapper = (
    <div className={inputWrapperClasses}>
      {inputIcon}
      <Typography>
        <input
          id={id.length ? id : null}
          aria-label={showLabel && label ? null : useableLabel}
          className={inputClasses}
          placeholder={placeholder}
          value={value}
          onChange={(event: SyntheticInputEvent<HTMLInputElement>) => {
            onChange(event.target.value);
          }}
          disabled={disabled}
          aria-invalid={hasError}
          aria-describedby={errorID}
          type={type}
          autoComplete={autoComplete}
          onKeyDown={onKeyDown}
          onClick={onClick}
          onFocus={onFocus}
          onBlur={onBlur}
          data-cy={cypress === null ? null : `${cypress}-input`}
          name={name.length ? name : null}
          ref={inputRef}
          inputMode={inputMode}
          required={required}
          noValidate
        />
      </Typography>
      {hasError && !hideErrorCross && (
        <CrossIcon
          className={bem(baseClass, "status-icon")}
          role="presentation"
          modifier="error"
        />
      )}
      {warning && !hasError && (
        <WarningIcon
          className={bem(baseClass, "status-icon")}
          role="presentation"
        />
      )}
      {showClearButton && !hasError && !warning && (
        <button
          className={bem(baseClass, "clear-button")}
          type="button"
          onClick={() => onChange("")}
          data-cy={cypress === null ? null : `${cypress}-clear`}
        >
          <ClearIcon />
        </button>
      )}
    </div>
  );
  let inputContainer;
  if (showLabel && label) {
    inputContainer = (
      <label className={`${baseClass}__label`}>
        <Typography size="secondary" bold color="light">
          <div className={`${baseClass}__labeltext`}>{label}</div>
        </Typography>
        {secondaryLabel ? (
          <Typography
            className={bem(baseClass, "label-secondary")}
            size="subtext"
            color="muted"
          >
            {secondaryLabel}
          </Typography>
        ) : null}
        {inputWrapper}
      </label>
    );
  } else {
    inputContainer = inputWrapper;
  }
  return (
    <div className={`${baseClass} ${className}`} data-cy={cypress}>
      {inputContainer}
      <div
        className={bem(baseClass, "errorContainer")}
        data-cy={`${cypress}-error`}
        id={errorID}
      >
        {errorComponent}
      </div>
    </div>
  );
};

export default InputBox;
