// @ts-nocheck
import React from "react";
import PhoneIconSvg from "../../../svg/qicons/phoneIcon.svg";
import type { IconSizes } from "../../../types/Icons";
import getSizeClass from "../../../helpers/getIconSizeClassname";
import styles from "./phoneIcon.module.scss";

type Props = {
  size: IconSizes;
};
const PhoneIcon = ({ size = "1x" }: Props) => {
  const sizeClass = getSizeClass(size);
  return <PhoneIconSvg className={`${styles.base} ${sizeClass}`} />;
};
export default PhoneIcon;
