// @ts-nocheck
import React from "react";
import StadiumSvg from "../../../svg/stadium.svg";
import "./stadium.scss";

const Stadium = () => {
  const baseClass = "c-stadium";
  return <StadiumSvg className={baseClass} />;
};
export default Stadium;
