// @ts-nocheck
import React, { forwardRef } from "react";
import bem from "@justpark/helpers/src/bem/bem";
import type { Node } from "react";
import Typography from "../Typography";
import "./link.scss";

export type Props = {
  children: Node;
  className?: string;
  cypress?: string | null;
  href: string;
  onBlur?: (event: SyntheticEvent) => any;
  onClick?: (event: SyntheticEvent) => any;
  onFocus?: (event: SyntheticEvent) => any;
  onKeyDown?: (event: SyntheticEvent) => any;
  size?: "h1" | "h2" | "h3" | "primary" | "secondary" | "subtext";
  tabIndex?: number | null;
  type?: "primary" | "secondary";
  target?: null;
  rel?: string | undefined | null;
  underline?: boolean;
};

/**
 * Styled hyperlinks.
 */

const Link = forwardRef(
  (
    {
      children,
      className = "",
      cypress = null,
      href,
      onBlur = () => {},
      onClick = () => {},
      onFocus = () => {},
      onKeyDown = () => {},
      size = "primary",
      tabIndex = null,
      type = "secondary",
      target = null,
      rel = "noopener noreferrer",
      underline = false
    }: Props,
    ref
  ) => {
    const baseClass = "c-link";
    const color = type === "primary" ? "brand" : "primary";
    const bold = type === "primary";
    return (
      <Typography
        className={`${bem(baseClass, null, {
          underline
        })} ${className}`}
        color={color}
        bold={bold}
        size={size}
      >
        <a
          data-cy={cypress}
          href={href}
          onBlur={onBlur}
          onClick={onClick}
          onFocus={onFocus}
          onKeyDown={onKeyDown}
          ref={ref}
          tabIndex={tabIndex}
          target={target}
          rel={rel}
        >
          {children}
        </a>
      </Typography>
    );
  }
);

export default Link;
