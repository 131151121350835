import React, { useEffect } from 'react';
import GoogleMapContext from '../components/GoogleMapContext';
import DestinationMarker from '../../../../static/images/center_marker.svg';
import type { Google } from '../types';

declare let google: Google;

/**
 * Add our custom marker to the center of the map
 * @param center
 * @param showCenter Boolean to toggle center marker on or off
 */
const useAddCenterMarker = (center, showCenter) => {
  const map = React.useContext(GoogleMapContext);

  // Create a marker without attaching it to a map
  const centerMarker = React.useMemo(
    () =>
      new google.maps.Marker({
        optimized: false,
        icon: {
          url: DestinationMarker,
          anchor: new google.maps.Point(31, 55),
          size: {
            width: 62,
            height: 70
          }
        },
        meta: {},
        zIndex: 5000,
        clickable: false
      }),
    []
  );

  useEffect(() => {
    const position = new google.maps.LatLng(center);
    centerMarker.setPosition(position);
  }, [centerMarker, center]);

  // Add or remove the marker from the map based on showCenter
  useEffect(() => {
    if (showCenter) {
      centerMarker.setMap(map);
    } else {
      centerMarker.setMap(null);
    }
  }, [map, centerMarker, showCenter]);
};

export default useAddCenterMarker;
