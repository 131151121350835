// @ts-nocheck
import React from "react";
import SparkleIconSvg from "../../../svg/qicons/sparkleIcon.svg";
import type { IconSizes } from "../../../types/Icons";
import getSizeClass from "../../../helpers/getIconSizeClassname";
import styles from "./sparkleIcon.module.scss";

type Props = {
  size: IconSizes;
  hug?: boolean;
};
const SparkleIcon = ({ size = "1x", hug = false }: Props) => {
  const sizeClass = getSizeClass(size);
  return (
    <SparkleIconSvg
      className={`${sizeClass} ${hug ? styles.hug : ""}`}
      viewBox={hug ? "5 4 13 16" : "0 0 24 24"}
    />
  );
};
export default SparkleIcon;
