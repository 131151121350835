import dayjs from 'dayjs';
import type { Dayjs } from 'dayjs';
import { stringify } from 'query-string';
import localDateTimeIso8601 from '@justpark/helpers/src/localDateTimeIso8601/localDateTimeIso8601';
import { HAS_EV_CHARGING } from './listingFacilities';

const buildSearchUrl = (
  address: any,
  arriving: Dayjs,
  leaving: Dayjs | undefined | null,
  monthlyDuration: string | undefined | null,
  partner: string,
  hasEv: boolean,
  monthlyType?: string | null,
  listingId?: string | null
): string => {
  const qs = {
    coords: `${address?.lat},${address?.lng}`,
    q: address?.city || address?.postalCode || address?.locationName,
    arriving: '',
    ...(!!listingId && { listingId })
  };

  if (dayjs.isDayjs(arriving)) {
    qs.arriving = arriving.format(localDateTimeIso8601);
  }

  if (monthlyDuration || monthlyDuration === 0) {
    qs.monthly_duration = monthlyDuration;
    qs.monthly_type = monthlyType || 'WEEKDAYS_ONLY';
  } else if (dayjs.isDayjs(leaving)) {
    qs.leaving = leaving.format(localDateTimeIso8601);
  }

  if (hasEv) {
    qs.filters = HAS_EV_CHARGING;
  }

  const partnerUrl = partner ? `/partner/${partner}` : '';

  return `${partnerUrl}/search/?${stringify(qs)}`;
};

export default buildSearchUrl;
