import React from 'react';
import Button from '@justpark/ui/src/components/Button';
import type { CheckoutSummaryResult } from '../../generated/graphql';
import CheckoutSummary from './CheckoutSummary';

import styles from '../../../static/scss/components/listingDetails/dateSelectorWrapper.module.scss';

type UIProps = {
  isSubmitDisabled: boolean;
  onSubmit: () => void;
  t: (b: string, a: string) => string;
  checkoutSummary: CheckoutSummaryResult | undefined | null;
  error: string | undefined | null;
  children: any;
};

const DateSelectorWrapper = ({
  t,
  children,
  checkoutSummary,
  error,
  isSubmitDisabled,
  onSubmit
}: UIProps) => (
  <div>
    {children}
    <div className={styles.priceWrapper}>
      <CheckoutSummary data={checkoutSummary} error={error} />
    </div>

    <Button
      className={styles.button}
      disabled={isSubmitDisabled}
      onClick={onSubmit}
      modifier="primary"
      cypress="book-space-button"
    >
      {isSubmitDisabled
        ? t('cta:bookThisSpace', 'Book this space')
        : t('cta:reserveLocationWithPrice', {
            priceFormatted:
              checkoutSummary.parkingFee?.formatted ||
              checkoutSummary.breakdown[0].formatted
          })}
    </Button>
  </div>
);

export default DateSelectorWrapper;
