// @ts-nocheck
import React from "react";
import CricketSvg from "../../../svg/cricket.svg";
import "./cricket.scss";

const Cricket = () => {
  const baseClass = "c-cricket";
  return <CricketSvg className={baseClass} />;
};
export default Cricket;
