import React from 'react';
import type { Dayjs } from 'dayjs';
import DayjsDateTime from './DayjsDateTime';
import styles from '../../../static/scss/components/ui/BookingDateTimeSelector.module.scss';

export type Props = {
  startTimeLabel?: string;
  endTimeLabel?: string;
  startTime: Dayjs | undefined | null;
  endTime: Dayjs | undefined | null;
  onStartTimeChange: (a: string) => void;
  onEndTimeChange: (a: string) => void;
  className?: string;
  datePlaceholder?: string;
  timePlaceholder?: string;
};

const cypress = 'booking-date-time';
const BookingDateTimeSelector = ({
  startTimeLabel,
  endTimeLabel,
  startTime,
  endTime,
  onStartTimeChange,
  onEndTimeChange,
  className = '',
  datePlaceholder = 'Select a date',
  timePlaceholder = 'Time'
}: Props) => (
  <div className={`${styles.wrapper} ${className}`} data-cy={cypress}>
    <div
      className={styles.dateRowTimePicker}
      data-cy={`${cypress}-start-date-time`}
    >
      <DayjsDateTime
        label={startTimeLabel}
        value={startTime}
        onChange={onStartTimeChange}
        datePlaceholder={datePlaceholder}
        timePlaceholder={timePlaceholder}
      />
    </div>

    <div
      className={styles.dateRowTimePicker}
      data-cy={`${cypress}-end-date-time`}
    >
      <DayjsDateTime
        label={endTimeLabel}
        value={endTime}
        onChange={onEndTimeChange}
        datePlaceholder={datePlaceholder}
        timePlaceholder={timePlaceholder}
      />
    </div>
  </div>
);

export default BookingDateTimeSelector;
