import React, { useMemo } from 'react';
import BreadcrumbLinks from '@justpark/ui/src/components/BreadcrumbLinks';

type Props = {
  breadcrumbs: Array<{ text: string; url: string }>;
};
const JPBreadcrumbs = ({ breadcrumbs }: Props) => {
  const relativeBreadcrumbLinks = useMemo(() => {
    const breadcrumbCount = breadcrumbs.length;

    return breadcrumbs.map((item, index) => ({
      text: item.text,
      url: index < breadcrumbCount - 1 ? new URL(item.url).pathname : null
    }));
  }, [breadcrumbs]);

  return (
    <BreadcrumbLinks>
      {/* TODO: If we sort out client side transitions into the landing page then change the below to use react router links */}
      {relativeBreadcrumbLinks.map(({ text, url }) =>
        url ? (
          <a key={text} href={url}>
            {text}
          </a>
        ) : (
          <span key={text}>{text}</span>
        )
      )}
    </BreadcrumbLinks>
  );
};

export default JPBreadcrumbs;
