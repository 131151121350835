// @ts-nocheck
import React from "react";
import CheckedSvg from "../../../svg/qicons/checked.svg";
import type { IconSizes } from "../../../types/Icons";
import getSizeClass from "../../../helpers/getIconSizeClassname";
import styles from "./checked.module.scss";

type Props = {
  size: IconSizes;
};
const Checked = ({ size = "1x" }: Props) => {
  const sizeClass = getSizeClass(size);
  return <CheckedSvg className={`${styles.base} ${sizeClass}`} />;
};
export default Checked;
