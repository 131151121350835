// @ts-nocheck
import React from "react";
import TrainStationSvg from "../../../svg/trainStation.svg";
import "./trainStation.scss";

const TrainStation = () => {
  const baseClass = "c-train-station";
  return <TrainStationSvg className={baseClass} />;
};
export default TrainStation;
