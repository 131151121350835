// @ts-nocheck
import React from "react";
import styles from "./availabilityStatus.module.scss";
import Typography from "../Typography";

type T = (b: string, a: string) => string;
type Props = {
  t: T;
};
type Status = "AVAILABLE" | "NOT_AVAILABLE" | "PARTIAL" | "FULLY_BOOKED";
const getStatusClassName = (status: Status) => {
  switch (status) {
    case "AVAILABLE":
      return styles.available;
    case "PARTIAL":
      return styles.partially;
    case "FULLY_BOOKED":
      return styles.booked;
    case "NOT_AVAILABLE":
      return styles.notAvailable;
    default:
      return "";
  }
};
export const StatusIndicator = ({
  status,
  message,
  messageClassName = "",
  modifierClassName = ""
}: {
  status: Status;
  message: string;
  messageClassName?: string;
  modifierClassName?: string;
}) => {
  const statusClassName = getStatusClassName(status);
  return (
    <div className={`${styles.status} ${statusClassName} ${modifierClassName}`}>
      <Typography className={`${styles.statusText} ${messageClassName}`}>
        {message}
      </Typography>
    </div>
  );
};
const AvailabilityStatus = ({ t }: Props) => {
  return (
    <div className={styles.grid}>
      <StatusIndicator
        message={t("listingPageCalendar:statusAvailable", "Available")}
        status="AVAILABLE"
        modifierClassName={styles.modifier}
      />
      <StatusIndicator
        message={t("listingPageCalendar:statusPartial", "Partially available")}
        status="PARTIAL"
        modifierClassName={styles.modifier}
      />
      <StatusIndicator
        message={t("listingPageCalendar:statusBooked", "Fully booked")}
        status="FULLY_BOOKED"
        modifierClassName={styles.modifier}
      />
      <StatusIndicator
        message={t("listingPageCalendar:statusUnavailable", "Unavailable")}
        status="NOT_AVAILABLE"
        modifierClassName={styles.modifier}
      />
    </div>
  );
};
export default AvailabilityStatus;
