import { useCallback } from 'react';
import { useSearchParams } from '@not-remix-run/react';

const useQueryStringTab = (tab: string) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const activeTab = Number(searchParams.get(tab)) || 0;
  const searchParamsObject = Object.fromEntries([...searchParams]);

  const onTabChange = useCallback(
    (index) => {
      setSearchParams(
        {
          ...searchParamsObject,
          [tab]: index
        },
        { replace: true, preventScrollReset: true }
      );
    },
    [searchParamsObject, setSearchParams, tab]
  );

  return [activeTab, onTabChange];
};

export default useQueryStringTab;
