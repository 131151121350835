// @ts-nocheck
import React from "react";
import FootballSvg from "../../../svg/football.svg";
import "./football.scss";

const Football = () => {
  const baseClass = "c-football";
  return <FootballSvg className={baseClass} />;
};
export default Football;
