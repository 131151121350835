// @ts-nocheck
import React from "react";
import MinusIconSvg from "../../../svg/qicons/minusIcon.svg";
import type { IconSizes } from "../../../types/Icons";
import getSizeClass from "../../../helpers/getIconSizeClassname";
import styles from "./minusIcon.module.scss";

type Props = {
  size: IconSizes;
};
const MinusIcon = ({ size = "1x" }: Props) => {
  const sizeClass = getSizeClass(size);
  return <MinusIconSvg className={`${styles.base} ${sizeClass}`} />;
};
export default MinusIcon;
