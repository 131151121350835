import React from 'react';
import LoadingWheel from '@justpark/ui/src/components/LoadingWheel';

import styles from '../../../static/scss/components/listingDetails/loadingState.module.scss';

const LoadingState = () => (
  <div className={styles.container}>
    <div className={styles.trustBanner} />
    <div className={styles.map} />
    <div className={styles.title} />
    <div className={styles.body}>
      <div className={styles.loadingWheel}>
        <LoadingWheel size="medium" />
      </div>
    </div>
  </div>
);

export default LoadingState;
