import React from 'react';
import Review from '@justpark/ui/src/components/Review';
import { useTranslation } from 'react-i18next';

import Typography from '@justpark/ui/src/components/Typography';
import LinkButton from '@justpark/ui/src/components/LinkButton';
import Heading from '@justpark/ui/src/components/Heading';
import NoReviewsInfoBox from '../UI/NoReviewsInfoBox';

import styles from '../../../static/scss/components/listingDetails/reviews.module.scss';

type Props = {
  reviews: any; // TODO: type
  fetchMore: any;
  totalCount: number;
  loading: boolean;
};

const cypress = 'listing-reviews';
const Reviews = ({ reviews, fetchMore, totalCount, loading }: Props) => {
  const { t } = useTranslation();

  const reviewCount = reviews.length;

  if (!reviewCount) {
    return <NoReviewsInfoBox t={t} cypress={`${cypress}-no-reviews`} />;
  }

  const showLoadMore = reviewCount < totalCount && reviewCount < 25;

  return (
    <div data-cy={cypress}>
      <Heading level={2}>
        {t('listingDetails:reviewsHeader', 'Reviews about this space')}
      </Heading>

      {reviews.map((review) => (
        <div data-cy={`${cypress}-review`} key={review.id}>
          <Review
            review={review}
            t={t}
            containerClassName={styles.reviewItem}
          />
        </div>
      ))}
      {showLoadMore && (
        <LinkButton
          className={styles.loadMore}
          cypress={`${cypress}-load-more`}
          disabled={loading}
          onClick={() =>
            fetchMore({
              variables: {
                reviewsOffset: reviewCount,
                reviewsLimit: 5
              }
            })
          }
        >
          <Typography
            color="brand"
            bold
            className={`${styles.loadMoreText} ${loading && styles.disabled}`}
          >
            <div>{t('cta:loadMoreReviews', 'Load more reviews')}</div>
          </Typography>
        </LinkButton>
      )}
    </div>
  );
};

export default Reviews;
