// @ts-nocheck
import React from "react";
import styles from "./threeTrustBanner.module.scss";
import Typography from "../Typography";
import BPGIcon from "../qicons/BPGIcon";
import Checked from "../qicons/Checked";
import PhoneIcon from "../qicons/PhoneIcon";
import type { Translate } from "../../types/i18n";
import defaultTranslate from "../../helpers/defaultTranslate";

type Props = {
  t?: Translate;
};
const ThreeTrustBanner = ({ t = defaultTranslate }: Props) => {
  return (
    <div className={styles.base}>
      <div className={styles.item}>
        <Typography
          size="secondary"
          color="primary-inverse"
          className={styles.text}
          bold
        >
          <BPGIcon size="1.25x" />
          {t("ui:threeTrustBanner-bestPrice", "Best price guarantee")}
        </Typography>
      </div>

      <div className={`${styles.item} ${styles.whiteIcon}`}>
        <Typography
          size="secondary"
          color="primary-inverse"
          className={styles.text}
          bold
        >
          <Checked size="1.25x" />

          {t(
            "ui:threeTrustBanner-trusted",
            "Trusted by over 10 million drivers"
          )}
        </Typography>
      </div>

      <div className={`${styles.item} ${styles.whiteIcon}`}>
        <Typography
          size="secondary"
          color="primary-inverse"
          className={styles.text}
          bold
        >
          <PhoneIcon size="1.25x" />
          {t(
            "ui:threeTrustBanner-customerSupport",
            "Award winning customer support"
          )}
        </Typography>
      </div>
    </div>
  );
};
export default ThreeTrustBanner;
