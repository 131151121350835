import { useMemo } from 'react';
import { useSearchParams } from '@not-remix-run/react';

const useProvideDates = () => {
  const [searchParams] = useSearchParams();

  const {
    fixed_start: fixedStart,
    fixed_end: fixedEnd,
    monthly_start: monthlyStart,
    monthly_type: monthlyType = 'WEEKDAYS_ONLY',
    booking_tab: bookingTab = 0
  } = Object.fromEntries([...searchParams]);

  return useMemo(
    () => ({
      fixedStart,
      fixedEnd,
      monthlyStart,
      monthlyType,
      bookingTab: Number(bookingTab)
    }),
    [fixedStart, fixedEnd, monthlyStart, monthlyType, bookingTab]
  );
};

export default useProvideDates;
