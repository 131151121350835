// @ts-nocheck
import React from "react";
import TubeSvg from "../../../svg/tube.svg";
import "./tube.scss";

const Tube = () => {
  const baseClass = "c-tube";
  return <TubeSvg className={baseClass} />;
};
export default Tube;
