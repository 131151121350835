import React from 'react';
import DriveUpBannerContent from './DriveUpBannerContent';
import DriveUpModal from './DriveUpModal';

import styles from '../../../static/scss/components/listingDetails/driveUpBanner.module.scss';

type DriveUpBannerProps = {
  locationId: number;
  isDriveUpModalOpen: boolean;
  onCloseDriveUpModal: () => void;
  seasonTicketLink: string | null;
};

const DriveUpBanner = ({
  locationId,
  isDriveUpModalOpen,
  onCloseDriveUpModal,
  seasonTicketLink
}: DriveUpBannerProps) => {
  const bannerContent = (
    <DriveUpBannerContent
      locationId={locationId}
      seasonTicketLink={seasonTicketLink}
    />
  );

  return (
    <>
      <div className={styles.driveUpBanner} data-cy="drive-up-banner">
        {bannerContent}
      </div>
      <DriveUpModal isOpen={isDriveUpModalOpen} onClose={onCloseDriveUpModal}>
        {bannerContent}
      </DriveUpModal>
    </>
  );
};

export default DriveUpBanner;
