const getEnsureParts = (ensure) => {
  if (ensure === null) {
    return null;
  }

  const [hourStr, minStr] = ensure.split(":");
  return [parseInt(hourStr, 10), parseInt(minStr, 10)];
};

const getTimeIntervalsForIncrement = (
  increment: number,
  ensureContains: string | null = null
) => {
  if (increment < 1) {
    throw new Error(`Invalid increment of ${increment}`);
  }

  const stepsInHour = 60 / increment;

  if (!Number.isInteger(stepsInHour)) {
    throw new Error(`Invalid increment of ${increment}`);
  }

  const values: string[] = [];

  const ensureParts = getEnsureParts(ensureContains);

  for (let hour = 0; hour < 24; hour += 1) {
    for (let min = 0; min < 60; min += increment) {
      values.push(`${`0${hour}`.slice(-2)}:${`0${min}`.slice(-2)}`);

      if (ensureParts) {
        // Ensure that a specific time appears in this list
        // Useful when we want to show interval of say 15mins, but the user has a custom booking date that otherwise would not appear in the list

        const currentIncrement = hour * 60 + min;
        const nextIncrement = currentIncrement + increment;

        const [ensureHour, ensureMin] = ensureParts;
        const ensureIncrement = ensureHour * 60 + ensureMin;

        if (
          ensureIncrement > currentIncrement &&
          ensureIncrement < nextIncrement
        ) {
          values.push(
            `${`0${ensureHour}`.slice(-2)}:${`0${ensureMin}`.slice(-2)}`
          );
        }
      }
    }
  }

  values.push("23:59");

  return values;
};

export default getTimeIntervalsForIncrement;
