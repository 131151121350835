// @ts-nocheck
import React, { useState } from "react";
import CollapsibleContainer from "../CollapsibleContainer";
import Typography from "../Typography";
import MinusIcon from "../qicons/MinusIcon";
import PlusIcon from "../qicons/PlusIcon";
import BasicButton from "../BasicButton";
import styles from "./FAQs.module.scss";

type FAQ = {
  question: string;
  answer: string;
  open: any;
  onChange: (value: any) => void;
};
type Props = {
  faqs: Array<FAQ>;
  canonical?: string;
};
type FAQProps = FAQ;
const FAQItem = ({ question, answer, open, onChange }: FAQProps) => {
  const isOpen = open === question;
  return (
    <div
      className={styles.item}
      data-cy="faq-item"
      itemScope
      itemProp="mainEntity"
      itemType="https://schema.org/Question"
    >
      <div className={styles.header}>
        <BasicButton
          className={styles.clickTarget}
          onClick={() => onChange(question)}
        >
          <Typography color="brand" bold>
            <span className={styles.headerText} itemProp="name">
              {question}
            </span>
          </Typography>
          <span className={styles.expandIcon}>
            {isOpen ? <MinusIcon size="1x" /> : <PlusIcon size="1x" />}
          </span>
        </BasicButton>
      </div>
      <div
        className={styles.contentContainer}
        itemScope
        itemProp="acceptedAnswer"
        itemType="https://schema.org/Answer"
      >
        <CollapsibleContainer isOpened={isOpen}>
          <div
            className={styles.content}
            itemProp="text"
            data-cy="faq-item-content"
          >
            {answer}
          </div>
        </CollapsibleContainer>
      </div>
    </div>
  );
};
const FAQs = ({ faqs, canonical }: Props) => {
  const [open, toggleOpen] = useState(null);
  const onChange = (id) => {
    toggleOpen(open === id ? null : id);
  };
  return (
    <div
      data-cy="faq-container"
      itemScope
      itemType="https://schema.org/FAQPage"
      itemID={canonical}
    >
      {faqs.map(({ question, answer }) => (
        <FAQItem
          key={question}
          question={question}
          answer={answer}
          open={open}
          onChange={onChange}
        />
      ))}
    </div>
  );
};
export default FAQs;
