/* globals google */
import React, { useContext, useMemo, useEffect, useCallback } from 'react';
import JustParkLocationPin from '@justpark/ui/src/components/Icons/JustParkLocationPin';
import GoogleMapLoader from './GoogleMapLoader';
import type { LatLngLiteral } from '../types';
import OverlayMarker from './OverlayMarker';
import GoogleMapContext from './GoogleMapContext';
import useAddCenterMarker from '../hooks/useAddCenterMarker';
import useAddEventListenerToMap from '../hooks/useAddEventListenerToMap';

type Props = {
  latLng: LatLngLiteral;
  zoom: number;
  directionsOrigin?: LatLngLiteral | null;
  mapCenterOffset?: () => number;
};
type DirectionsProps = {
  origin: LatLngLiteral;
  destination: LatLngLiteral;
};

const Directions = ({ origin, destination }: DirectionsProps) => {
  const map = useContext(GoogleMapContext);

  const directionsRequest = useMemo(
    () => ({
      origin,
      destination,
      travelMode: 'WALKING'
    }),
    [origin, destination]
  );

  useEffect(() => {
    const directionsRenderer = new google.maps.DirectionsRenderer({
      map,
      suppressMarkers: true,
      preserveViewport: true,
      polylineOptions: {
        clickable: false,
        strokeColor: '#1fa637',
        strokeOpacity: 0.5,
        strokeWeight: 5
      }
    });

    const directionsService = new google.maps.DirectionsService();

    directionsService.route(directionsRequest, (result, status) => {
      if (status === 'OK') {
        directionsRenderer.setDirections(result);

        const topRoute = result.routes[0].bounds;

        map.fitBounds(topRoute, {
          top: 55,
          bottom: 10,
          left: 25,
          right: 25
        });
      }
    });

    return () => {
      directionsRenderer.setMap(null);
    };
  }, [map, directionsRequest]);

  useAddCenterMarker(destination, true);

  return null;
};

const MapOffset = ({ mapCenterOffset }) => {
  const map = useContext(GoogleMapContext);

  const offsetMap = useCallback(() => {
    if (map.getProjection() === undefined) {
      return;
    }

    const offsetx = mapCenterOffset();

    const scale = 2 ** map.getZoom();

    const worldCoordinateCenter = map
      .getProjection()
      .fromLatLngToPoint(map.getCenter());

    const pixelOffset = new google.maps.Point(offsetx / scale || 0, 0);

    const worldCoordinateNewCenter = new google.maps.Point(
      worldCoordinateCenter.x - pixelOffset.x,
      worldCoordinateCenter.y + pixelOffset.y
    );

    const newCenter = map
      .getProjection()
      .fromPointToLatLng(worldCoordinateNewCenter);

    map.setCenter(newCenter);
  }, [map, mapCenterOffset]);

  useAddEventListenerToMap('projection_changed', offsetMap);

  useEffect(() => {
    offsetMap();
  }, [offsetMap]);
};

const MapBannerGoogleMap = ({
  latLng,
  zoom,
  directionsOrigin,
  mapCenterOffset
}: Props) => (
  <GoogleMapLoader
    center={latLng}
    disableDefaultUI
    zoom={zoom}
    mapTypeId="roadmap"
    gestureHandling="none"
    scrollwheel={false}
    zoomControl
  >
    {mapCenterOffset && <MapOffset mapCenterOffset={mapCenterOffset} />}
    {directionsOrigin && (
      <Directions origin={latLng} destination={directionsOrigin} />
    )}

    <OverlayMarker lat={latLng.lat} lng={latLng.lng}>
      <JustParkLocationPin theme="brand" placement="bottom" />
    </OverlayMarker>
  </GoogleMapLoader>
);

export default MapBannerGoogleMap;
