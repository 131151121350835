// @ts-nocheck
import React from "react";
import Typography from "../Typography";
import Button from "../Button";
import styles from "./deactivatedSpaceBanner.module.scss";

type Props = {
  onButtonClick: () => void;
  message: string;
  buttonLabel: string;
  cypress?: string;
  className?: string;
};
const DeactivatedSpaceBanner = ({
  onButtonClick,
  message,
  buttonLabel,
  cypress = "",
  className = ""
}: Props) => {
  return (
    <div className={`${styles.banner} ${className}`} data-cy={cypress}>
      <Typography>{message}</Typography>
      <div>
        <Button cypress="deactivated-space-cta" onClick={onButtonClick}>
          {buttonLabel}
        </Button>
      </div>
    </div>
  );
};

export default DeactivatedSpaceBanner;
