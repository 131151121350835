import React, { useEffect, useContext } from 'react';
import loadable from '@loadable/component';
import bem from '@justpark/helpers/src/bem/bem';
import Tabs, { TabItem } from '@justpark/ui/src/components/Tabs';
import type { Dayjs } from 'dayjs';
import { useLazyQuery } from '@apollo/client';
import BrowserContext from '../../context/BrowserContext';
import MonthlyDateSelector from './MonthlyDateSelector';
import useQueryStringTab from '../../hooks/useQueryStringTab';
import FixedTermDateSelector from './FixedTermDateSelector';
import type { GetListingCheckoutSummaryQuery } from '../../generated/graphql';
import CHECKOUT_SUMMARY from './listingDetailsCheckoutSummary.graphql';
import CHECKOUT_SUMMARY_MONTHLY from './listingDetailsMonthlyCheckoutSummary.graphql';
import useProvideDates from './hooks/useProvideDates';
import DatesContext from './DatesContext';
import BookingPanel from './BookingPanel';

import styles from '../../../static/scss/components/listingDetails/bookingDetails.module.scss';

const BookSpaceModal = loadable(() => import('./BookSpaceModal'));

type Props = {
  t: (b: string, a: string) => string;
  isBookingModalOpen: boolean;
  onCloseBookingModal: () => void;
  listingId: number;
  onBeforeSubmit: () => void;
  acceptsMonthly: boolean;
  acceptsFixedTerm: boolean;
};
type PriceLookupResponse = {
  data: any;
  loading: boolean;
  error: any;
};
type BookingProps = {
  t: (b: string, a: string) => string;
  onTabChange: (a: number) => void;
  activeTab: number;
  acceptsFixedTerm: boolean;
  acceptsMonthly: boolean;
  listingId: number;
  onBeforeSubmit: () => void;
  fixedTermPrice: PriceLookupResponse;
  monthlyPrice: PriceLookupResponse;
  showFixedTerm: boolean;
};

const BookingInformation = ({
  t,
  activeTab,
  onTabChange,
  acceptsMonthly,
  acceptsFixedTerm,
  onBeforeSubmit,
  listingId,
  fixedTermPrice,
  monthlyPrice,
  showFixedTerm
}: BookingProps) => (
  <div className={bem('tabs', 'container', {}, styles)}>
    {acceptsFixedTerm && acceptsMonthly && (
      <Tabs
        active={activeTab}
        theme="minimal-black"
        className={styles.tabs}
        onChange={onTabChange}
        evenSpacing
      >
        <TabItem cypress="booking-tab" size="secondary">
          {t('search:tabsHourlyDaily', 'Hourly / Daily')}
        </TabItem>
        <TabItem cypress="booking-tab" size="secondary">
          {t('search:tabsMonthly', 'Monthly')}
        </TabItem>
      </Tabs>
    )}

    {showFixedTerm && (
      <FixedTermDateSelector
        onBeforeSubmit={onBeforeSubmit}
        listingId={listingId}
        price={fixedTermPrice}
      />
    )}

    {!showFixedTerm && (
      <MonthlyDateSelector
        onBeforeSubmit={onBeforeSubmit}
        listingId={listingId}
        price={monthlyPrice}
      />
    )}
  </div>
);

const useFixedTermPriceLookup = (
  startDateTime: Dayjs | undefined | null,
  endDateTime: Dayjs | undefined | null,
  id: number
) => {
  const [getCheckoutSummary, response]: [
    () => void,
    {
      loading: boolean;
      error: any;
      data: GetListingCheckoutSummaryQuery;
    }
  ] = useLazyQuery(CHECKOUT_SUMMARY, {
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (startDateTime && endDateTime) {
      getCheckoutSummary({
        variables: {
          id,
          startDate: startDateTime,
          endDate: endDateTime
        }
      });
    }
  }, [startDateTime, endDateTime, getCheckoutSummary, id]);

  return response;
};

const useMonthlyPriceLookup = (
  startDateTime: Dayjs | undefined | null,
  monthlyType: string,
  id: number
) => {
  const [getMonthlyCheckoutSummary, response]: [
    () => void,
    {
      loading: boolean;
      error: any;
      data: GetListingCheckoutSummaryQuery;
    }
  ] = useLazyQuery(CHECKOUT_SUMMARY_MONTHLY, {
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (startDateTime) {
      getMonthlyCheckoutSummary({
        variables: {
          id,
          startDate: startDateTime,
          endDate: null,
          monthlyDuration: '0',
          monthlyApplicableDays: monthlyType.toLowerCase()
        }
      });
    }
  }, [startDateTime, getMonthlyCheckoutSummary, monthlyType, id]);

  return response;
};

const BookingDetails = ({
  t,
  isBookingModalOpen,
  onCloseBookingModal,
  listingId,
  onBeforeSubmit,
  acceptsMonthly,
  acceptsFixedTerm
}: Props) => {
  const isOnlyMonthly = acceptsMonthly && !acceptsFixedTerm;
  const isOnlyFixedTerm = acceptsFixedTerm && !acceptsMonthly;
  const browser = useContext(BrowserContext);
  const isLarge = browser.greaterThan.medium;

  useEffect(() => {
    if (isLarge) {
      onCloseBookingModal();
    }
  }, [onCloseBookingModal, isLarge]);

  const dates = useProvideDates();
  const {
    fixedStart: startDateTime,
    fixedEnd: endDateTime,
    monthlyStart: monthlyStartDateTime,
    monthlyType,
    bookingTab: activeTab
  } = dates;

  const onTabChange = useQueryStringTab('booking_tab')[1];

  const fixedTermPrice = useFixedTermPriceLookup(
    startDateTime,
    endDateTime,
    listingId
  );

  const monthlyPrice = useMonthlyPriceLookup(
    monthlyStartDateTime,
    monthlyType,
    listingId
  );

  const showFixedTerm = (activeTab === 0 && !isOnlyMonthly) || isOnlyFixedTerm;

  const information = (
    <DatesContext.Provider value={dates}>
      <BookingInformation
        t={t}
        activeTab={activeTab}
        onTabChange={onTabChange}
        acceptsMonthly={acceptsMonthly}
        acceptsFixedTerm={acceptsFixedTerm}
        showFixedTerm={showFixedTerm}
        onBeforeSubmit={onBeforeSubmit}
        listingId={listingId}
        fixedTermPrice={fixedTermPrice}
        monthlyPrice={monthlyPrice}
      />
    </DatesContext.Provider>
  );

  return (
    <>
      <BookingPanel t={t}>{information}</BookingPanel>

      <BookSpaceModal
        t={t}
        isOpen={isBookingModalOpen}
        onClose={onCloseBookingModal}
        isMonthly
      >
        {information}
      </BookSpaceModal>
    </>
  );
};

export default BookingDetails;
