// @ts-nocheck
import React from "react";
import PlusIconSvg from "../../../svg/qicons/plusIcon.svg";
import type { IconSizes } from "../../../types/Icons";
import getSizeClass from "../../../helpers/getIconSizeClassname";
import styles from "./plusIcon.module.scss";

type Props = {
  size?: IconSizes;
  className?: string;
};
const PlusIcon = ({ className = "", size = "1x" }: Props) => {
  const sizeClass = getSizeClass(size);
  return <PlusIconSvg className={`${styles.base} ${sizeClass} ${className}`} />;
};
export default PlusIcon;
