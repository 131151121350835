import { useSearchParams } from '@not-remix-run/react';

const useSetDatesParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const searchParamsObject = Object.fromEntries([...searchParams]);

  return (params: any) =>
    setSearchParams(
      {
        ...searchParamsObject,
        ...params
      },
      { replace: true, preventScrollReset: true }
    );
};

export default useSetDatesParams;
