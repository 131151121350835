// @ts-nocheck
import React, { useState } from "react";
import bem from "@justpark/helpers/src/bem/bem";
import ToolTip from "../ToolTip";
import type { Props as ToolTipProps } from "../ToolTip";
import useShouldEnableNative from "../../hooks/useShouldEnableNative";
import Modal from "../Modal";
import ModalHeader from "../ModalHeader";
import "./toolTipNativeModal.scss";

type Props = ToolTipProps & {
  modalId: number | string;
  theme?: string;
  portalParent?: string;
  smallHeader?: boolean;
  modalContainerClassName?: string;
  modalHeadingClassName?: string;
  modalCloseButtonClassName?: string;
};
const baseClass = "c-tool-tip-native-modal";
const ToolTipNativeModal = ({
  children,
  position = "top",
  content,
  interactive = true,
  trigger = "mouseenter",
  className = "",
  hideOnClick = false,
  maxWidth = "none",
  hideOnClickChild = false,
  visible,
  distance = 10,
  modalId,
  theme = "white",
  portalParent = "#portal",
  contentTitle = "",
  smallHeader = false,
  modalContainerClassName,
  modalHeadingClassName,
  modalCloseButtonClassName
}: Props) => {
  const useNative = useShouldEnableNative();
  const [isOpen, setIsOpen] = useState(false);
  const contentClass = bem(baseClass, "content-container");
  if (useNative) {
    return (
      <>
        <button
          className={bem(baseClass, "children-button")}
          type="button"
          onClick={(e) => {
            e.stopPropagation();
            setIsOpen(!isOpen);
          }}
        >
          {children}
        </button>

        <Modal
          onClose={() => setIsOpen(false)}
          id={modalId}
          isOpen={isOpen}
          closeOnOutsideClick
          portalParent={portalParent}
        >
          <div className={`${contentClass} ${modalContainerClassName}`}>
            <ModalHeader
              onClose={() => setIsOpen(false)}
              heading={contentTitle}
              smallHeader={smallHeader}
              headingClassName={modalHeadingClassName}
              closeButtonClassName={modalCloseButtonClassName}
            />

            {content}
          </div>
        </Modal>
      </>
    );
  }
  return (
    <ToolTip
      position={position}
      content={content}
      interactive={interactive}
      trigger={trigger}
      className={className}
      hideOnClick={hideOnClick}
      maxWidth={maxWidth}
      hideOnClickChild={hideOnClickChild}
      visible={visible}
      distance={distance}
      theme={theme}
    >
      <div className={bem(baseClass, "children-container")}>{children}</div>
    </ToolTip>
  );
};

export default ToolTipNativeModal;
