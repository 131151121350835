import React, { useState, useCallback, useEffect } from 'react';
import DateTimeDropDown from '@justpark/ui/src/components/DateTimeDropDown';
import { Dayjs } from 'dayjs';
import convertToDayjs from '@justpark/helpers/src/dates/convertToDayjs';
import getDayjsInstance from '@justpark/helpers/src/dates/getDayjsInstance';
import { useTranslation } from 'react-i18next';

export const useDayjsToDateTimeHandlers = (dayjsDate, onChange) => {
  const dateFormat = 'YYYY-MM-DD';
  const timeFormat = 'HH:mm';
  const [date, setDate] = useState(() => {
    if (dayjsDate) {
      return dayjsDate.format(dateFormat);
    }

    return '';
  });
  const [time, setTime] = useState(() => {
    if (dayjsDate) {
      return dayjsDate.format(timeFormat);
    }

    return '';
  });

  useEffect(() => {
    if (dayjsDate) {
      setDate(dayjsDate.format(dateFormat));
      setTime(dayjsDate.format(timeFormat));
    }
  }, [dayjsDate]);

  const onDateChange = useCallback(
    (str: string) => {
      let changedDate = convertToDayjs(str);

      if (dayjsDate) {
        changedDate = changedDate
          .set('hour', dayjsDate.hour())
          .set('minute', dayjsDate.minute())
          .set('seconds', 0);
      } else {
        changedDate = changedDate
          .set('hour', 12)
          .set('minute', 0)
          .set('seconds', 0);
      }

      onChange(changedDate);
    },
    [dayjsDate, onChange]
  );

  const onTimeChange = useCallback(
    (str) => {
      const [hour, min] = str.split(':');
      let base = dayjsDate || getDayjsInstance();

      base = base.set('hour', hour).set('minute', min).set('seconds', 0);

      return onChange(base);
    },
    [dayjsDate, onChange]
  );

  return [date, onDateChange, time, onTimeChange];
};
type Props = {
  value: Dayjs;
  onChange: (a: Dayjs) => void;
  label: string;
  datePlaceholder: string;
  timePlaceholder: string;
  hideDate?: boolean;
  min?: string | null;
};

const DayjsDateTime = ({
  value,
  onChange,
  label,
  datePlaceholder,
  timePlaceholder,
  hideDate = false,
  min = ''
}: Props) => {
  const { t } = useTranslation();

  const [date, dateChange, time, timeChange] = useDayjsToDateTimeHandlers(
    value,
    onChange
  );

  return (
    <DateTimeDropDown
      date={date}
      onDateChange={dateChange}
      onTimeChange={timeChange}
      datePlaceholder={datePlaceholder}
      timePlaceholder={timePlaceholder}
      time={time}
      label={label}
      timeInterval={15}
      hideDate={hideDate}
      t={t}
      min={min}
    />
  );
};

export default DayjsDateTime;
