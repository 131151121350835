import React from 'react';

import InfoBox from '@justpark/ui/src/components/InfoBox';
import Typography from '@justpark/ui/src/components/Typography';

type Props = {
  t: (b: string, a: string) => string;
  cypress?: string;
};

const NoReviewsInfoBox = ({ t, cypress = '' }: Props) => (
  <InfoBox type="green" cypress={cypress}>
    <Typography size="secondary" color="light" fontStyle="italic">
      {t('search:noReviewsText', 'No review comments left for this location.')}
    </Typography>
  </InfoBox>
);

export default NoReviewsInfoBox;
