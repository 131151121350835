const webPage = ({
  canonical,
  title,
  description,
  breadcrumbs,
  about,
  nodes = [],
  audience = []
}: {
  canonical: string;
  title: string;
  description: string;
  breadcrumbs: any;
  about: any;
  nodes: Array<any>;
  audience: Array<any>;
}) => ({
  '@context': 'https://schema.org',
  '@graph': [
    {
      '@type': 'WebPage',
      '@id': canonical,
      url: canonical,
      name: title,
      isPartOf: {
        '@id': 'https://www.justpark.com/#/schema/WebSite/1'
      },
      description,
      potentialAction: {
        '@id': 'https://www.justpark.com/#/schema/DownloadAction/1'
      },
      publisher: {
        '@id': 'https://www.justpark.com/#/schema/Organization/1'
      },
      ...('@id' in breadcrumbs
        ? {
            breadcrumb: {
              '@id': breadcrumbs['@id']
            }
          }
        : {}),
      ...('@id' in about
        ? {
            about: {
              '@id': about['@id']
            }
          }
        : {}),
      ...('@id' in audience[0]
        ? {
            audience: {
              '@id': audience[0]['@id']
            }
          }
        : {})
    },
    ...('@id' in about ? [about] : []),
    ...('@id' in breadcrumbs ? [breadcrumbs] : []),
    ...nodes,
    ...audience
  ]
});

export default webPage;
