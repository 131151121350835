import { useMemo } from 'react';

const useMapBannerSizes = (images) => {
  const mapBannerSizes = useMemo(
    () =>
      images?.reduce((acc, item) => {
        acc[item.size] = item.src;
        return acc;
      }, {}),
    [images]
  );

  return mapBannerSizes || [];
};

export default useMapBannerSizes;
