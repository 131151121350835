import React, { useContext, useMemo } from 'react';
import { useNavigate } from '@not-remix-run/react';
import NativeFancyDropdown from '@justpark/ui/src/components/NativeFancyDropdown';
import { useTranslation } from 'react-i18next';
import SingleDatePickerDropDown from '@justpark/ui/src/components/SingleDatePickerDropDown';
import convertToDayjs from '@justpark/helpers/src/dates/convertToDayjs';
import formatDate from '@justpark/helpers/src/dates/formatDate';
import convertToDayjsIfDate from '@justpark/helpers/src/dates/convertToDayjsIfDate';
import { useDayjsToDateTimeHandlers } from '../UI/DayjsDateTime';
import type { CheckoutSummaryResult } from '../../generated/graphql';
import type { MonthlyType } from '../../types';
import DateSelectorWrapper from './DateSelectorWrapper';
import onSubmit from './helpers/onSubmit';
import DatesContext from './DatesContext';
import useSetDatesParams from './hooks/useSetDatesParams';

import styles from '../../../static/scss/components/listingDetails/monthlyDateSelector.module.scss';

type PriceLookupResponse = {
  data: any;
  loading: boolean;
  error: any;
};

export type Props = {
  listingId: number;
  onBeforeSubmit: () => void;
  price: PriceLookupResponse;
};
type UIProps = {
  isSubmitDisabled: boolean;
  onSubmitMonthly: () => void;
  t: (b: string, a: string) => string;
  date: string | undefined | null;
  dateChange: () => void;
  checkoutSummary: CheckoutSummaryResult | undefined | null;
  error: string | undefined | null;
  monthlyType: string;
  setMonthlyType: (a: MonthlyType) => void;
  translatedOptions: Array<string>;
};

const UI = ({
  t,
  date,
  dateChange,
  checkoutSummary,
  error,
  isSubmitDisabled,
  onSubmitMonthly,
  monthlyType,
  setMonthlyType,
  translatedOptions
}: UIProps) => (
  <DateSelectorWrapper
    checkoutSummary={checkoutSummary}
    error={error}
    t={t}
    isSubmitDisabled={isSubmitDisabled}
    onSubmit={onSubmitMonthly}
  >
    <div data-cy="monthlyDateSelector" className={styles.dateWrapper}>
      <div className={styles.datePickerWrapper}>
        <SingleDatePickerDropDown
          label={t('dates:parkingFrom', 'Parking from')}
          className={styles.datePicker}
          error=""
          hideErrorCross
          date={date}
          placeholder={t('dates:selectADate', 'Select a date')}
          onChange={(newDate) => dateChange(convertToDayjs(newDate))}
          datePlaceholder={t('dates:selectADate', 'Select a date')}
          labelColor="primary"
        />
      </div>

      <div className={styles.durationWrapper}>
        <NativeFancyDropdown
          label={t('dates:longTermBookingDurationLabel', 'Duration')}
          onChange={setMonthlyType}
          options={translatedOptions}
          selected={monthlyType}
          labelColor="primary"
        />
      </div>
    </div>
  </DateSelectorWrapper>
);

const options = [
  { value: 'STANDARD', label: '7 days per week' },
  {
    value: 'WEEKDAYS_ONLY',
    label: 'Mon-Fri only'
  }
];

const MonthlyDateSelector = ({ onBeforeSubmit, listingId, price }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { monthlyStart, monthlyType } = useContext(DatesContext);
  const setParams = useSetDatesParams();

  const setMonthlyType = (data) => setParams({ monthly_type: data });
  const setStartDateTime = (date) =>
    setParams({ monthly_start: formatDate(date) });

  const [date, dateChange] = useDayjsToDateTimeHandlers(
    convertToDayjsIfDate(monthlyStart),
    (dayjsDate) => setStartDateTime(dayjsDate.startOf('day'))
  );

  const onSubmitMonthly = () =>
    onSubmit({
      arriving: convertToDayjs(monthlyStart),
      leaving: null,
      isMonthly: true,
      monthlyType,
      listingId,
      monthlyDuration: '0',
      navigate,
      onBeforeSubmit
    });

  const translatedOptions = useMemo(
    () =>
      options.map(({ value: optionValue, label: optionLabel }) => ({
        value: optionValue,
        label: t(`longTermDurationPicker:${optionValue}`, optionLabel)
      })),
    [t]
  );

  const { data, error } = price;

  const checkoutSummary = data?.listing?.checkoutSummary;
  const isSubmitDisabled = checkoutSummary?.__typename !== 'CheckoutSummary';

  return (
    <UI
      t={t}
      isSubmitDisabled={isSubmitDisabled}
      setMonthlyType={setMonthlyType}
      monthlyType={monthlyType}
      checkoutSummary={checkoutSummary}
      date={date}
      onSubmitMonthly={onSubmitMonthly}
      dateChange={dateChange}
      translatedOptions={translatedOptions}
      error={error}
    />
  );
};

export default MonthlyDateSelector;
