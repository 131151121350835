const roundToLowest100 = (number: number) => {
  if (number < 100) {
    return number;
  }

  if (number >= 500) {
    return 500;
  }

  return Math.floor(number / 100) * 100;
};

export default roundToLowest100;
