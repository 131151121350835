// @ts-nocheck
import * as React from "react";
import styles from "./breadcrumbLinks.module.scss";
import Typography from "../Typography";

type Props = {
  children: React.ChildrenArray<React.Element<any>>;
};
const BreadcrumbLinks = ({ children }: Props) => {
  return (
    <>
      {React.Children.map(children, (child) => (
        <Typography size="secondary" color="muted" className={styles.link}>
          {child}
        </Typography>
      ))}
    </>
  );
};
export default BreadcrumbLinks;
