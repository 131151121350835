import React from 'react';
import useMatchMediaMatches from '@justpark/ui/src/hooks/useMatchMediaMatches';
import pngPixel from '@justpark/helpers/src/pngPixel/pngPixel';
import MapBannerGoogleMap from './MapBannerGoogleMap';
import type { LatLngLiteral } from '../types';

import styles from '../../../../static/scss/components/map/mapBanner.module.scss';

type Props = {
  latLng: LatLngLiteral;
  staticMapImages: any;
  directionsOrigin?: LatLngLiteral | null;
  mapCenterOffset?: () => number;
};

const zoom = 16;

const MapBanner = ({
  latLng,
  staticMapImages,
  directionsOrigin,
  mapCenterOffset
}: Props) => {
  const showGoogleMap = useMatchMediaMatches('(min-width: 641px)');

  return (
    <div className={styles.wrapper}>
      <picture className={styles.picture}>
        <source media="(min-width: 641px)" srcSet={pngPixel()} />
        <source
          media="(min-width: 576px)"
          srcSet={staticMapImages['640x270']}
          width={640}
          height={270}
        />

        <source
          media="(min-width: 476px)"
          srcSet={staticMapImages['575x160']}
          width={575}
          height={160}
        />

        <source
          media="(min-width: 376px)"
          srcSet={staticMapImages['475x160']}
          width={475}
          height={160}
        />

        <source
          media="(min-width: 321px)"
          srcSet={staticMapImages['375x160']}
          width={375}
          height={160}
        />

        <source
          media="(min-width: 0)"
          srcSet={staticMapImages['320x160']}
          width={320}
          height={160}
        />

        <img
          className={styles.image}
          src={staticMapImages['320x160']}
          alt="Location"
          width="320"
          height="160"
          decoding="async"
        />
      </picture>
      {showGoogleMap && (
        <div className={styles.googleMap}>
          <MapBannerGoogleMap
            latLng={latLng}
            zoom={zoom}
            directionsOrigin={directionsOrigin}
            mapCenterOffset={mapCenterOffset}
          />
        </div>
      )}
    </div>
  );
};

export default MapBanner;
