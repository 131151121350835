import React, { useContext } from 'react';
import { useNavigate } from '@not-remix-run/react';
import { useTranslation } from 'react-i18next';
import convertToDayjs from '@justpark/helpers/src/dates/convertToDayjs';
import formatDate from '@justpark/helpers/src/dates/formatDate';
import convertToDayjsIfDate from '@justpark/helpers/src/dates/convertToDayjsIfDate';
import type { CheckoutSummaryResult } from '../../generated/graphql';
import BookingDateTimeSelector from '../UI/BookingDateTimeSelector';
import DateSelectorWrapper from './DateSelectorWrapper';
import onSubmit from './helpers/onSubmit';
import DatesContext from './DatesContext';
import useSetDatesParams from './hooks/useSetDatesParams';
import getDefaultBookingDurationHours from '../../helpers/getDefaultBookingDurationHours';

type PriceLookupResponse = {
  data: any;
  loading: boolean;
  error: any;
};

export type Props = {
  listingId: number;
  onBeforeSubmit: () => void;
  price: PriceLookupResponse;
};
type UIProps = {
  isSubmitDisabled: boolean;
  onSubmitFixed: () => void;
  t: (b: string, a: string) => string;
  startTime: string | undefined | null;
  endTime: string | undefined | null;
  onStartTimeChange: () => void;
  onEndTimeChange: () => void;
  checkoutSummary: CheckoutSummaryResult | undefined | null;
  error: string | undefined | null;
};

const UI = ({
  t,
  startTime,
  endTime,
  onStartTimeChange,
  onEndTimeChange,
  checkoutSummary,
  error,
  isSubmitDisabled,
  onSubmitFixed
}: UIProps) => (
  <DateSelectorWrapper
    checkoutSummary={checkoutSummary}
    error={error}
    t={t}
    isSubmitDisabled={isSubmitDisabled}
    onSubmit={onSubmitFixed}
  >
    <BookingDateTimeSelector
      startTimeLabel={t('dates:parkingFrom', 'Parking from')}
      endTimeLabel={t('dates:parkingUntil', 'Parking until')}
      startTime={convertToDayjsIfDate(startTime)}
      endTime={convertToDayjsIfDate(endTime)}
      onStartTimeChange={onStartTimeChange}
      onEndTimeChange={onEndTimeChange}
      datePlaceholder={t('dates:selectADate', 'Select a date')}
      timePlaceholder={t('dates:time', 'Time')}
    />
  </DateSelectorWrapper>
);

const FixedTermDateSelector = ({ onBeforeSubmit, listingId, price }: Props) => {
  const { fixedStart, fixedEnd } = useContext(DatesContext);

  const dayjsEnd = fixedEnd && convertToDayjs(fixedEnd);
  const { t } = useTranslation();
  const setDates = useSetDatesParams();
  const navigate = useNavigate();

  const setEndDateTime = (date) => setDates({ fixed_end: formatDate(date) });

  const onSubmitFixed = () =>
    onSubmit({
      arriving: convertToDayjs(fixedStart),
      leaving: convertToDayjs(fixedEnd),
      isMonthly: false,
      monthlyType: null,
      listingId,
      monthlyDuration: null,
      navigate,
      onBeforeSubmit
    });

  const { data, error } = price;

  const checkoutSummary = data?.listing?.checkoutSummary;
  const isSubmitDisabled = checkoutSummary?.__typename !== 'CheckoutSummary';

  const onStartTimeChange = (value) => {
    if (!fixedEnd || dayjsEnd?.isSameOrBefore(value)) {
      return setDates({
        fixed_start: formatDate(value),
        fixed_end: formatDate(
          value.add(getDefaultBookingDurationHours(), 'hours')
        )
      });
    }

    return setDates({ fixed_start: formatDate(value) });
  };

  const onEndTimeChange = (value) => {
    if (value.isSameOrBefore(fixedStart)) {
      return setEndDateTime(
        convertToDayjs(fixedStart).add(
          getDefaultBookingDurationHours(),
          'hours'
        )
      );
    }

    return setEndDateTime(value);
  };

  return (
    <UI
      t={t}
      startTime={fixedStart}
      endTime={fixedEnd}
      onStartTimeChange={onStartTimeChange}
      onEndTimeChange={onEndTimeChange}
      checkoutSummary={checkoutSummary}
      error={error}
      isSubmitDisabled={isSubmitDisabled}
      onSubmitFixed={onSubmitFixed}
    />
  );
};

export default FixedTermDateSelector;
