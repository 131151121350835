// @ts-nocheck
import React from "react";
import type { Node } from "react";
import styles from "./badge.module.scss";

type Props = {
  children: Node;
  className?: string;
  cypress?: string;
};
const Badge = ({ className = "", cypress = "", children }: Props) => {
  return (
    <div className={`${styles.badge} ${className}`} data-cy={cypress}>
      {children}
    </div>
  );
};

export default Badge;
