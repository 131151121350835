import trimTrailingSlash from '@justpark/helpers/src/trimTrailingSlash/trimTrailingSlash';

// eslint-disable-next-line import/prefer-default-export
export const getBreadCrumbs = (
  canonical: string,
  breadcrumbs: { title: string; url: string }
) => {
  const defaultBreadcrumbs = [
    {
      title: 'JustPark',
      url: 'https://www.justpark.com/'
    }
  ];

  const items = [defaultBreadcrumbs[0], ...breadcrumbs];

  const breadcrumbArray = items.map((breadcrumb, index) => ({
    '@type': 'ListItem',
    position: index + 1,
    name: breadcrumb.title,
    item:
      index === items.length - 1
        ? {
            '@id': breadcrumb.url
          }
        : {
            '@type': 'WebPage',
            '@id': breadcrumb.url,
            name: breadcrumb.title,
            sameAs: breadcrumb.url,
            url: breadcrumb.url,
            isPartOf: {
              '@id': 'https://www.justpark.com/#/schema/WebSite/1'
            },
            publisher: {
              '@id': 'https://www.justpark.com/#/schema/Organization/1'
            }
          }
  }));

  return {
    '@type': 'BreadcrumbList',
    '@id': `${trimTrailingSlash(canonical)}/#/schema/BreadcrumbList/1`,
    itemListElement: breadcrumbArray
  };
};
