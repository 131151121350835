import React, {
  useEffect,
  useRef,
  useState,
  useMemo,
  useCallback
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from '@not-remix-run/react';
import { useSearchParams } from '@not-remix-run/react';
import Typography from '@justpark/ui/src/components/Typography';
import DeactivatedSpaceBanner from '@justpark/ui/src/components/DeactivatedSpaceBanner';
import Button from '@justpark/ui/src/components/Button';
import useMatchMediaMatches from '@justpark/ui/src/hooks/useMatchMediaMatches';
import AnchorTabs from '@justpark/ui/src/components/AnchorTabs';
import ThreeTrustBanner from '@justpark/ui/src/components/ThreeTrustBanner';
import { useSelector } from 'react-redux';
import RatingWithBookingCount from '@justpark/ui/src/components/RatingWithBookingCount';
import ListingFAQs from './ListingFAQs';
import MapBanner from '../Map/components/MapBanner';
import BookingDetails from './BookingDetails';
import type { Listing } from '../../generated/graphql';
import DriveUpBanner from './DriveUpBanner';
import AdminBanner from './AdminBanner';
import { selectIsAdminOrIsSuperuser } from '../../stores/auth';
import useTrackViewItem from '../../hooks/useTrackViewItem';
import { gtagTrack } from '../../helpers/Tracking/Analytics';
import useMapBannerSizes from '../../hooks/useMapBannerSizes';
import Reviews from './Reviews';
import Information from './Information';
import Availability from './Availability';
import Amenities from './Amenities';
import ListingGallery from './ListingGallery';
import JPBreadcrumbs from '../UI/JPBreadcrumbs';
import styles from '../../../static/scss/components/listingDetails/listingDetails.module.scss';
import '../../../static/scss/components/map.scss';
import AddressContext from './addressContext';
import TrustpilotWidget from '../Widgets/TrustPilotWidget';
import TrustedAirport from '../Search/TrustedAirport';

type Props = {
  location: Listing;
  fetchMore: (a: any) => void;
  loading: boolean;
};

const cypress = 'listing-details';

const useStickyIntersectionRef = () => {
  const stickyAvailabilityRef = useRef();
  const stickyAvailabilityIntersectionRef = useRef();

  useEffect(() => {
    if (
      !('IntersectionObserver' in window) ||
      !stickyAvailabilityIntersectionRef.current
    ) {
      return () => {};
    }

    const observer = new IntersectionObserver(
      ([e]) =>
        stickyAvailabilityRef.current?.classList?.toggle(
          styles.mobileCheckAvailabilityButtonContainerIsSticky,
          e.intersectionRatio < 1
        ),
      { threshold: [1] }
    );

    observer.observe(stickyAvailabilityIntersectionRef.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  return { stickyAvailabilityRef, stickyAvailabilityIntersectionRef };
};

const ListingDetails = ({ location, fetchMore, loading }: Props) => {
  const {
    id,
    title,
    staticMapImages,
    acceptsMonthly,
    acceptsFixedTerm,
    canonicalUrl,
    isTrustedAirport
  } = location;
  useTrackViewItem(id, title);

  const navigate = useNavigate();
  const { t } = useTranslation();

  const [isBookingModalOpen, setIsBookingModalOpen] = useState(false);
  const [isDriveUpModalOpen, setIsDriveUpModalOpen] = useState(false);

  const isDesktop = useMatchMediaMatches('(min-width: 860px)') === true;

  const isAdminOrIsSuperuser = useSelector(selectIsAdminOrIsSuperuser);

  const mapBannerImages = useMapBannerSizes(staticMapImages);

  useEffect(() => {
    if (isDesktop && isDriveUpModalOpen) {
      setIsDriveUpModalOpen(false);
    }
  }, [isDesktop, isDriveUpModalOpen, setIsDriveUpModalOpen]);

  const {
    stickyAvailabilityRef,
    stickyAvailabilityIntersectionRef
  } = useStickyIntersectionRef();

  const isActive = location.active;
  const isDriveup = location.acceptsPrebook === false;

  const {
    address: { lat, lng }
  } = location;
  const showDriveUpBanner = location.acceptsDriveup && !location.acceptsPrebook;
  const showAvailability = !isDriveup && isActive;

  const [searchParams] = useSearchParams();

  const directonsLat = searchParams.get('lat');
  const directonsLng = searchParams.get('lng');

  const mapDirections = useMemo(() => {
    if (directonsLat && directonsLng) {
      return {
        lat: parseFloat(directonsLat),
        lng: parseFloat(directonsLng)
      };
    }

    return null;
  }, [directonsLat, directonsLng]);

  const latLng = useMemo(
    () => ({
      lat,
      lng
    }),
    [lat, lng]
  );

  const reviews = location.reviews.nodes;
  const hasReviews = reviews.length > 0;
  const hasNearby = !!location.amenities?.length;

  const anchorLinks = useMemo(() => {
    const items = [
      {
        title: t('listingDetails:descriptionTab', 'Description'),
        anchor: 'description'
      }
    ];

    if (showAvailability) {
      items.push({
        title: t('listingDetails:availabilityTab', 'Availability'),
        anchor: 'availability'
      });
    }

    if (hasReviews) {
      items.push({
        title: t('listingDetails:reviewsTab', 'Reviews'),
        anchor: 'reviews'
      });
    }

    if (hasNearby) {
      items.push({
        title: t('listingDetails:nearbyTab', 'Nearby'),
        anchor: 'nearby'
      });
    }

    return items;
  }, [hasNearby, hasReviews, showAvailability, t]);

  const anchorTargetRef = useRef();
  const leftColumnRef = useRef();

  const calculateMapOffset = useCallback(() => {
    const boundingRect = leftColumnRef.current.getBoundingClientRect();
    const leftColCenter = boundingRect.left + boundingRect.width / 2;

    return leftColCenter - window.innerWidth / 2;
  }, [leftColumnRef]);

  return (
    <AddressContext.Provider value={location.address}>
      <div data-cy="listing-details" className={styles.listingDetails}>
        <div className={styles.trustBanner}>
          <div className={styles.trustBannerContent}>
            <ThreeTrustBanner t={t} />
          </div>
        </div>

        <div className={styles.map}>
          <MapBanner
            staticMapImages={mapBannerImages}
            latLng={latLng}
            directionsOrigin={mapDirections}
            mapCenterOffset={calculateMapOffset}
            title={title}
          />
        </div>

        <div className={styles.listingTitle}>
          {location.breadcrumbs.length > 0 ? (
            <div
              className={styles.breadcrumbsContainer}
              data-cy="breadcrumb-container"
            >
              <JPBreadcrumbs breadcrumbs={location.breadcrumbs} />
            </div>
          ) : null}
          <div className={styles.listingTitleContent}>
            <div>
              <Typography className={styles.heading} bold>
                <h1>{location.title}</h1>
              </Typography>
              <div data-cy={`${cypress}-rating`} className={styles.rating}>
                {isTrustedAirport ? (
                  <TrustedAirport />
                ) : (
                  <RatingWithBookingCount
                    rating={location.feedback.averageRating}
                    bookingCount={location.bookingCount}
                    t={t}
                  />
                )}
              </div>
            </div>
            <div className={styles.trustPilotContainer}>
              <TrustpilotWidget height="67px" />
            </div>
          </div>
        </div>

        <div className={styles.main}>
          <div
            className={isActive ? styles.left : styles.leftListingDisabled}
            ref={leftColumnRef}
          >
            <div className={styles.tabsBoxContainer} ref={anchorTargetRef}>
              <div className={styles.tabsBox}>
                <AnchorTabs items={anchorLinks} targetRef={anchorTargetRef} />
              </div>
            </div>

            {isAdminOrIsSuperuser && (
              <div className={styles.adminBannerWrapper}>
                <AdminBanner listingId={location.id} userId={location.userId} />
              </div>
            )}

            <div className={styles.galleryContainer}>
              <ListingGallery isLCP location={location} t={t} />
            </div>

            <div id="description" className={styles.scrollTarget} />
            <div className={styles.box}>
              <Information location={location} />
            </div>

            {showAvailability && (
              <>
                <div id="availability" className={styles.scrollTarget} />
                <div className={styles.box}>
                  <Availability />
                </div>
              </>
            )}

            {hasReviews && (
              <>
                <div id="reviews" className={styles.scrollTarget} />
                <div className={styles.box}>
                  <Reviews
                    reviews={reviews}
                    fetchMore={fetchMore}
                    totalCount={location.reviews.totalCount}
                    loading={loading}
                  />
                </div>
              </>
            )}

            {hasNearby && (
              <>
                <div id="nearby" className={styles.scrollTarget} />
                <div className={styles.box}>
                  <Amenities amenities={location.amenities} />
                </div>
              </>
            )}

            <div className={styles.box}>
              <ListingFAQs canonical={canonicalUrl} />
            </div>
          </div>
        </div>

        {isActive && (
          <div className={styles.right}>
            {showDriveUpBanner ? (
              <DriveUpBanner
                locationId={location.id}
                isDriveUpModalOpen={isDriveUpModalOpen}
                onCloseDriveUpModal={() => setIsDriveUpModalOpen(false)}
                seasonTicketLink={location.seasonTicketLink}
              />
            ) : (
              <BookingDetails
                acceptsMonthly={acceptsMonthly}
                acceptsFixedTerm={acceptsFixedTerm}
                t={t}
                isBookingModalOpen={isBookingModalOpen}
                onCloseBookingModal={() => setIsBookingModalOpen(false)}
                listingId={location.id}
                onBeforeSubmit={() => {
                  gtagTrack('add_to_cart', {
                    listingId: location.id,
                    listingTitle: location.title
                  });
                }}
              />
            )}
          </div>
        )}

        {isActive && (
          <>
            <div
              className={styles.mobileCheckAvailabilityButtonContainer}
              ref={stickyAvailabilityRef}
            >
              <Button
                className={styles.mobileCheckAvailabilityButton}
                onClick={() => {
                  if (showDriveUpBanner) {
                    setIsDriveUpModalOpen(true);
                  } else {
                    setIsBookingModalOpen(true);
                  }
                }}
                cypress="mobile-check-availability"
              >
                {showDriveUpBanner
                  ? t(
                      'listingDetails:paymentInstruction',
                      'Payment instructions'
                    )
                  : t('listingDetails:checkAvailability', 'Check availability')}
              </Button>
            </div>
            <div ref={stickyAvailabilityIntersectionRef} />
          </>
        )}

        {!isActive && (
          <DeactivatedSpaceBanner
            className={styles.banner}
            onButtonClick={() => navigate('/')}
            cypress="space-deactivated-banner"
            message={t(
              'listingDetails:deactivatedMessage',
              'This listing has been deactivated.'
            )}
            buttonLabel={t(
              'listingDetails:deactivatedCTA',
              'Find available parking'
            )}
          />
        )}
      </div>
    </AddressContext.Provider>
  );
};

export default ListingDetails;
