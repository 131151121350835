// @ts-nocheck
import React from "react";
import type { Node } from "react";
import Link from "../Link";
import styles from "./hrefButton.module.scss";

type Props = {
  children: Node;
  className?: string;
  cypress?: string | null;
  href: string;
  onBlur?: (event: SyntheticEvent) => any;
  onClick?: (event: SyntheticEvent) => any;
  onFocus?: (event: SyntheticEvent) => any;
  onKeyDown?: (event: SyntheticEvent) => any;
  tabIndex?: number | null;
  type?: "primary" | "secondary";
  size?: "h1" | "h2" | "h3" | "primary" | "secondary" | "subtext";
  target?: null;
  rel?: string | undefined | null;
  block?: boolean;
  outline?: boolean;
};

const HrefButton = ({
  className = "",
  cypress = null,
  href,
  onBlur = () => {},
  onClick = () => {},
  onFocus = () => {},
  onKeyDown = () => {},
  type = "primary",
  target = null,
  rel = "noopener noreferrer",
  tabIndex = null,
  size = "primary",
  block = false,
  children,
  outline = false
}: Props) => {
  const typeClass = type === "primary" ? styles.primary : styles.secondary;
  return (
    <Link
      className={`${styles.hrefButton} ${typeClass} ${className} ${
        block && styles.block
      } ${outline && styles.outline}`}
      href={href}
      target={target}
      rel={rel}
      tabIndex={tabIndex}
      onBlur={onBlur}
      onClick={onClick}
      onFocus={onFocus}
      onKeyDown={onKeyDown}
      cypress={cypress}
      size={size}
    >
      {children}
    </Link>
  );
};

export default HrefButton;
