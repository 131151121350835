import React from 'react';
import type { Node } from 'react';
import Modal from '@justpark/ui/src/components/Modal';
import CrossIcon from '@justpark/ui/src/components/Icons/CrossIcon';

import styles from '../../../static/scss/components/listingDetails/driveUpModal.module.scss';

type DriveUpModalProps = {
  isOpen: boolean;
  onClose: () => void;
  children: Node;
};

const DriveUpModal = ({ isOpen, onClose, children }: DriveUpModalProps) => (
  <Modal isOpen={isOpen} onClose={onClose} id="drive-up" portalParent="#portal">
    <div className={styles.wrapper} data-cy="drive-up-modal">
      <CrossIcon onClick={onClose} className={styles.close} />
      {children}
    </div>
  </Modal>
);

export default DriveUpModal;
