import React, { useState, useCallback, useMemo } from 'react';
import { useParams } from '@not-remix-run/react';
import { useQuery } from '@apollo/client';
import Error from '@justpark/ui/src/components/Error';
import CalendarGrid from '@justpark/ui/src/components/CalendarGrid';
import { useTranslation } from 'react-i18next';
import getPlaceholderData from '@justpark/ui/src/components/CalendarGrid/getPlaceholderData';
import Heading from '@justpark/ui/src/components/Heading';
import getDayjsInstance from '@justpark/helpers/src/dates/getDayjsInstance';
import convertToDayjs from '@justpark/helpers/src/dates/convertToDayjs';
import GET_AVAILABILITY_QUERY from '../../queries/listingDetails/getAvailabilityQuery.graphql';

import styles from '../../../static/scss/components/listingDetails/availability.module.scss';

const Availability = () => {
  const [dateObject, setDateObject] = useState(getDayjsInstance());
  const [count, setCount] = useState(0);
  const [isPrevDisabled, setIsPrevDisabled] = useState(true);

  const { t } = useTranslation();
  const { slug } = useParams();

  const date = dateObject.format('YYYY-MM');
  const firstDayOfMonth = parseInt(
    convertToDayjs(dateObject).startOf('month').format('d'),
    10
  );

  const placeHolderData = useMemo(() => getPlaceholderData(dateObject), [
    dateObject
  ]);

  const { error, data, loading } = useQuery(GET_AVAILABILITY_QUERY, {
    variables: {
      slug,
      date
    },
    ssr: false
  });

  const onMonthChange = useCallback(
    (actionType: 'prev' | 'next') => {
      const state = actionType === 'next' ? 1 : -1;
      setDateObject((prev) => {
        const cloned = convertToDayjs(prev.add(state, 'month'));
        const isToday = getDayjsInstance().isSame(cloned, 'day');
        setIsPrevDisabled(isToday);
        return cloned;
      });
      setCount((prev) => prev + state);
    },
    [setDateObject, setCount]
  );

  const availability = data?.listing?.availability || placeHolderData;

  return (
    <>
      <Heading level={2}>
        {t('listingDetails:availabilityHeader', 'Real-time availability')}
      </Heading>
      <div className={styles.main}>
        {error && <Error error={error.toString()} />}
        {!error && (
          <CalendarGrid
            data={availability.days}
            firstDayOfMonth={firstDayOfMonth}
            month={availability.month}
            year={availability.year}
            title={convertToDayjs(
              `${availability.year}-${availability.month}`
            ).format('MMMM YYYY')}
            onMonthChange={onMonthChange}
            t={t}
            disablePrevBtn={isPrevDisabled}
            disableNextBtn={count === 6}
            cypress="availability-calendar"
            loading={loading}
          />
        )}
      </div>
    </>
  );
};

export default Availability;
