// @ts-nocheck
import React from "react";
import MinimalStarSvg from "../../../svg/qicons/minimalStar.svg";
import type { IconSizes } from "../../../types/Icons";
import getSizeClass from "../../../helpers/getIconSizeClassname";
import styles from "./minimalStar.module.scss";

type Props = {
  size: IconSizes;
  modifier?: "half" | "empty";
};
const MinimalStar = ({ size = "1x", modifier }: Props) => {
  const sizeClass = getSizeClass(size);
  return (
    <MinimalStarSvg
      className={`${styles.base} ${sizeClass} ${
        modifier === "half" ? styles.half : ""
      } ${modifier === "empty" ? styles.empty : ""}`}
    />
  );
};
export default MinimalStar;
