// @ts-nocheck
import React, { lazy, Suspense } from "react";
import bem from "@justpark/helpers/src/bem/bem";
import "tippy.js/dist/tippy.css";
import "./toolTip.scss";

const Tippy = lazy(() => import("@tippy.js/react"));
export type Props = {
  children?: any;
  position?:
    | "top"
    | "left"
    | "bottom"
    | "right"
    | "top-start"
    | "left-start"
    | "bottom-start"
    | "right-start"
    | "top-end"
    | "left-end"
    | "bottom-end"
    | "right-end";
  content: string | any;
  interactive?: boolean;
  trigger?: "mouseenter" | "focus" | "click" | "manual";
  className?: string;
  hideOnClick?: boolean;
  maxWidth?: number | "none";
  hideOnClickChild?: boolean;
  visible?: boolean;
  distance?: number;
  theme?: string;
};
const hideOnEscPress = {
  name: "hideOnEscPress",
  defaultValue: true,
  fn(instance) {
    function eventListener(event) {
      if (instance.props.hideOnEscPress && event.keyCode === 27) {
        instance.hide();
      }
    }
    return {
      onCreate() {
        document.addEventListener("keydown", eventListener);
      },
      onDestroy() {
        document.removeEventListener("keydown", eventListener);
      }
    };
  }
};
const hideOnClickItem = {
  name: "hideOnClickItem",
  defaultValue: false,
  fn(instance) {
    const eventListener = () => instance.hide();
    return {
      onCreate() {
        instance.popper.addEventListener("click", eventListener);
      },
      onDestroy() {
        instance.popper.removeEventListener("click", eventListener);
      }
    };
  }
};

/**
 * Tooltip for dynamically showing content
 * TODO: The tooltips don't work when running under enzyme so Tippy is disabled for tests
 */
const ToolTip = ({
  children = null,
  position = "top",
  content,
  interactive = true,
  trigger = "mouseenter",
  className = "",
  hideOnClick = true,
  maxWidth = "none",
  hideOnClickChild = false,
  visible,
  distance = 10,
  theme = "white"
}: Props) => {
  const tooltipClass = bem("c-tool-tip", null, {
    "string-only": typeof content === "string"
  });
  const plugins = hideOnClickChild
    ? [hideOnClickItem, hideOnEscPress]
    : [hideOnEscPress];
  return process.env.IS_SERVER ? null : (
    <Suspense fallback={<div />}>
      {process.env.NODE_ENV !== "test" && (
        <Tippy
          placement={position}
          content={[content]}
          className={`${tooltipClass} ${className}`}
          theme={theme}
          hideOnClick={hideOnClick}
          interactive={interactive}
          trigger={trigger}
          maxWidth={maxWidth}
          appendTo={document.body}
          plugins={plugins}
          visible={visible}
          distance={distance}
        >
          <span>{children}</span>
        </Tippy>
      )}
    </Suspense>
  );
};

export default ToolTip;
