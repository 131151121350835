// @ts-nocheck
import React, { useState, useRef, useCallback } from "react";
import formatDateForUserDisplay from "@justpark/helpers/src/dates/formatDateForUserDisplay";
import convertToDayjs from "@justpark/helpers/src/dates/convertToDayjs";
import dayjs from "dayjs";
import bem from "@justpark/helpers/src/bem/bem";
import localDateTimeIso8601 from "@justpark/helpers/src/localDateTimeIso8601/localDateTimeIso8601";
import advancedFormat from "dayjs/plugin/advancedFormat";
import InputBox from "../InputBox";
import ChevronIcon from "../Icons/ChevronIcon";
import LazySingleDatePicker from "../LazySingleDatePicker";
import useOutsideClickHandler from "../../hooks/useOutsideClickHandler";
import useNativeInputs from "../../hooks/useNativeInputs";
import Typography from "../Typography";
import "./singleDatePickerDropDown.scss";

type Props = {
  date: string | undefined | null;
  error: string;
  hideErrorCross: boolean;
  onChange: (a: string) => void;
  className?: string;
  disabled?: boolean;
  max?: string | null;
  min?: string | null;
  placeholder?: string;
  label?: string;
  labelColor?: string;
  t: (key?: string, defaultString?: string) => string;
};
const baseClass = "c-single-date-picker-drop-down";
dayjs.extend(advancedFormat);
const SingleDatePickerDropDown = ({
  date,
  onChange,
  className = "",
  error,
  hideErrorCross,
  disabled = false,
  min = dayjs().format("YYYY-MM-DD"),
  max = null,
  placeholder = "Select start date",
  label = "",
  labelColor = "light",
  t = () => "Do MMM YYYY"
}: Props) => {
  const [showDatePicker, setShowDatePicker] = useState(false);
  const node = useRef();
  const inputRef = useRef();
  const onClose = useCallback(() => setShowDatePicker(false), []);
  useOutsideClickHandler(node, onClose, showDatePicker);
  const useNative = useNativeInputs();

  const formattedDate = date
    ? formatDateForUserDisplay(
        convertToDayjs(date),
        "dateFormat:dayMonthYear",
        "Do MMM YYYY",
        t
      )
    : "";

  const onClickEvent = useCallback((e: MouseEvent) => {
    e.stopPropagation();
  }, []);

  const onFancyInputClick = useCallback((event: MouseEvent) => {
    event.target.blur();
    event.stopPropagation();
    event.preventDefault();
    setShowDatePicker(true);
  }, []);

  return (
    <div ref={node} className={`${baseClass} ${className}`}>
      {label && (
        <label className={bem(baseClass, "label")}>
          <Typography size="secondary" bold color={labelColor}>
            <div className={bem(baseClass, "labeltext")}>{label}</div>
          </Typography>
        </label>
      )}

      <div className={bem(baseClass, "date-input-wrapper")}>
        {useNative && (
          <input
            type="date"
            className={bem(baseClass, "date-input")}
            value={date || ""}
            onClick={onClickEvent}
            ref={inputRef}
            min={min}
            max={max}
            onChange={(event) => {
              onChange(event.target.value);
            }}
          />
        )}

        <InputBox
          className={bem(baseClass, "date")}
          value={formattedDate}
          placeholder={placeholder}
          icon={<ChevronIcon />}
          iconPosition="right"
          hideErrorCross={hideErrorCross}
          onClick={onFancyInputClick}
          onFocus={onFancyInputClick}
          error={error}
          disabled={disabled}
          onChange={() => {}}
        />
      </div>

      {showDatePicker && (
        <LazySingleDatePicker
          date={
            date
              ? dayjs(date).format(localDateTimeIso8601)
              : dayjs().add(1, "day").format(localDateTimeIso8601)
          }
          onChange={(dateValue) => {
            onChange(dateValue);
            setShowDatePicker(false);
          }}
          maxDate={max}
          minDate={convertToDayjs(min).format(localDateTimeIso8601)}
        />
      )}
    </div>
  );
};
export default SingleDatePickerDropDown;
