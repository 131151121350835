// @ts-nocheck
import React, { useCallback, useEffect, useState } from "react";
import Typography from "../Typography";

import styles from "./anchorTabs.module.scss";

type Props = {
  items: Array<{
    title: string;
    anchor: string;
  }>;
  targetRef: any;
};

const useIntersection = (items, setActiveId, targetRef) => {
  const scroll = useCallback(() => {
    const documentHeight = document.body.scrollHeight;
    const currentScroll = window.scrollY + window.innerHeight;
    if (currentScroll >= documentHeight) {
      setActiveId(items[items.length - 1].anchor);
      return;
    }

    const target = targetRef.current?.getBoundingClientRect();

    const activeItems = items.filter((item) => {
      const itemRect = document
        .getElementById(item.anchor)
        .getBoundingClientRect();

      return itemRect.top - 1 <= target?.bottom;
    });

    const currentItem = activeItems.pop()?.anchor || items[0].anchor;

    setActiveId(currentItem);
  }, [items, setActiveId, targetRef]);

  useEffect(() => {
    document.addEventListener("scroll", scroll, {
      passive: true
    });

    return () => {
      document.removeEventListener("scroll", scroll, {
        passive: true
      });
    };
  }, [scroll]);

  return null;
};

const AnchorTabs = ({ items, targetRef }: Props) => {
  const [activeId, setActiveId] = useState(() => {
    return items[0].anchor;
  });

  useIntersection(items, setActiveId, targetRef);

  const onClick = useCallback((event) => {
    event.preventDefault();
    const { anchor } = event.target.dataset;
    const element = document.getElementById(anchor);

    // https://github.com/cypress-io/cypress/issues/23355
    // eslint-disable-next-line indent
    const options = window.Cypress
      ? {}
      : {
          behavior: "smooth"
        };

    element.scrollIntoView(options);
  }, []);

  return (
    <div data-cy="anchor-tabs" className={styles.base}>
      {items.map(({ title, anchor }) => {
        const isActive = anchor === activeId;

        return (
          <Typography key={anchor} className={`${styles.link}`}>
            <a
              href={`#${anchor}`}
              title={title}
              data-anchor={anchor}
              onClick={onClick}
              data-cy={`anchor-tab-${anchor}`}
              data-active={isActive}
            >
              {title}
            </a>
          </Typography>
        );
      })}
    </div>
  );
};

export default AnchorTabs;
