// @ts-nocheck
import React from "react";
import type { Element } from "react";
import bem from "@justpark/helpers/src/bem/bem";
import Heading from "../Heading";
import Typography from "../Typography";
import CrossIcon from "../Icons/CrossIcon";
import "./modalHeader.scss";

type Props = {
  onClose: () => void;
  heading: string | Element<any>;
  headingDetail?: string;
  theme?: "minimalist";
  smallHeader?: boolean;
  headingClassName?: string;
  closeButtonClassName?: string;
  hideCrossIcon?: boolean;
};
const ModalHeader = ({
  onClose,
  heading,
  headingDetail = null,
  theme = "",
  smallHeader = false,
  headingClassName,
  closeButtonClassName,
  hideCrossIcon = false
}: Props) => {
  const baseClass = "c-modal-header";
  const headingCls = bem(baseClass, "heading-detail");
  const closeCls = bem(baseClass, "close-button");
  return (
    <div
      className={bem(baseClass, null, {
        headingDetail:
          headingDetail !== null && headingDetail.trim().length > 0,
        theme,
        smallHeader
      })}
    >
      {heading ? (
        <div className={`${headingCls} ${headingClassName}`}>
          <Heading level={2} bold>
            {heading}
          </Heading>
          {headingDetail && (
            <Typography size="primary" color="light">
              {headingDetail}
            </Typography>
          )}
        </div>
      ) : null}

      {!hideCrossIcon && (
        <button
          data-cy="Close modal"
          className={`${closeCls} ${closeButtonClassName}`}
          type="button"
          onClick={onClose}
        >
          <CrossIcon />
        </button>
      )}
    </div>
  );
};
export default ModalHeader;
