// @ts-nocheck
import React from "react";
import bem from "@justpark/helpers/src/bem/bem";
import JustParkLocationPinSvg from "../../../svg/justParkLocationPin.svg";
import "./justParkLocationPin.scss";

type Props = {
  theme?: "light" | "brand";
  transitionTheme?: boolean;
  placement?: "center" | "bottom";
};
const JustParkLocationPin = ({
  theme = "light",
  transitionTheme = false,
  placement = "center"
}: Props) => {
  const baseClass = "c-just-park-location-pin";
  return (
    <div
      className={bem(baseClass, null, {
        theme,
        placement,
        "transition-theme": transitionTheme
      })}
    >
      <JustParkLocationPinSvg />
    </div>
  );
};
export default JustParkLocationPin;
