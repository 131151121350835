// @ts-nocheck
import React from "react";
import BPGIconSvg from "../../../svg/qicons/bpgIcon.svg";
import type { IconSizes } from "../../../types/Icons";
import getSizeClass from "../../../helpers/getIconSizeClassname";

type Props = {
  size: IconSizes;
};
const BPGIcon = ({ size = "1x" }: Props) => {
  const sizeClass = getSizeClass(size);
  return <BPGIconSvg className={sizeClass} />;
};
export default BPGIcon;
