import React from 'react';
import TubeIcon from '@justpark/ui/src/components/Icons/Tube';
import TrainStationIcon from '@justpark/ui/src/components/Icons/TrainStation';
import AirplaneIcon from '@justpark/ui/src/components/Icons/Airplane';
import CricketIcon from '@justpark/ui/src/components/Icons/Cricket';
import DlrIcon from '@justpark/ui/src/components/Icons/Dlr';
import FootballIcon from '@justpark/ui/src/components/Icons/Football';
import OvergroundIcon from '@justpark/ui/src/components/Icons/Overground';
import RugbyIcon from '@justpark/ui/src/components/Icons/Rugby';
import InfoIcon from '@justpark/ui/src/components/Icons/InfoIcon';
import StadiumIcon from '@justpark/ui/src/components/Icons/Stadium';
import Typography from '@justpark/ui/src/components/Typography';

import Heading from '@justpark/ui/src/components/Heading';
import { useTranslation } from 'react-i18next';
import styles from '../../../static/scss/components/listingDetails/amenities.module.scss';
import LegacyLink from '../Common/LegacyLink';

type Category = 'transport' | 'stadium';
type Subcategory =
  | 'airport'
  | 'cricket'
  | 'dlr'
  | 'rugbyleague'
  | 'rugbyunion'
  | 'football'
  | 'underground'
  | 'lul'
  | 'national-rail'
  | 'nationalrail'
  | 'overground';
type Amenity = {
  category: Category;
  subcategory: Subcategory;
  name: string;
  distance: string;
  slug: string;
  countryCode: string;
};
type AmenitiesType = Array<Amenity>;
type Props = {
  amenities: AmenitiesType;
};

const returnIcon = (category: Category, subcategory: Subcategory) => {
  if (subcategory === 'airport') {
    return <AirplaneIcon />;
  }
  if (subcategory === 'cricket') {
    return <CricketIcon />;
  }
  if (subcategory === 'dlr') {
    return <DlrIcon />;
  }
  if (subcategory === 'football') {
    return <FootballIcon />;
  }
  if (subcategory === 'lul' || subcategory === 'underground') {
    return <TubeIcon />;
  }
  if (subcategory === 'national-rail' || subcategory === 'nationalrail') {
    return <TrainStationIcon />;
  }
  if (subcategory === 'overground') {
    return <OvergroundIcon />;
  }
  if (subcategory === 'rugbyleague' || subcategory === 'rugbyunion') {
    return <RugbyIcon />;
  }
  if (category === 'stadium') {
    return <StadiumIcon />;
  }
  return <InfoIcon size="small" />;
};

const Amenities = ({ amenities }: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Heading level={2}>
        {t('listingDetails:amenitiesHeader', 'Points of interest')}
      </Heading>

      <div className={styles.amenities} data-cy="amenities">
        {amenities.map(
          ({
            category,
            subcategory,
            name,
            distance,
            slug,
            countryCode,
            path
          }) => (
            <div
              className={styles.amenity}
              key={`${subcategory}-${name}-${distance}`}
            >
              {returnIcon(category.toLowerCase(), subcategory?.toLowerCase())}
              <div className={styles.info}>
                <Typography size="secondary" bold>
                  <LegacyLink
                    to={`${path || `${countryCode}/parking/${slug}/`}`}
                    target="_blank"
                    data-cy={`amenity-link-${slug}`}
                  >
                    {name}
                  </LegacyLink>
                </Typography>
                <Typography size="secondary" color="muted">
                  {distance}
                </Typography>
              </div>
            </div>
          )
        )}
      </div>
    </>
  );
};

export default Amenities;
