// @ts-nocheck
import dayjs from "dayjs";
import firstDayOfMonth from "@justpark/helpers/src/firstDayOfMonth/firstDayOfMonth";

const getPlaceholderData = (dateObject) => {
  const now = dateObject || dayjs();
  const daysInMonth = now.daysInMonth();
  const month = now?.get("month");
  const year = now?.get("year");
  const data = [];

  // eslint-disable-next-line no-plusplus
  for (let i = 1; i <= daysInMonth; i++) {
    data.push({
      day: i,
      status: "PAST",
      availability: [],
      totalBookings: 0
    });
  }
  return {
    firstDayOfMonth: firstDayOfMonth(month, year),
    title: `${dayjs().month(month)} ${year}`,
    month: dateObject.format("MM"),
    year,
    days: data
  };
};
export default getPlaceholderData;
