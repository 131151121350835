const sameAsLookUp = (country) => {
  const sameAs = {
    GB: 'https://en.wikipedia.org/wiki/United_Kingdom',
    US: 'https://en.wikipedia.org/wiki/United_States',
    AU: 'https://en.wikipedia.org/wiki/Australia',
    AT: 'https://en.wikipedia.org/wiki/Austria',
    CA: 'https://en.wikipedia.org/wiki/Canada',
    DK: 'https://en.wikipedia.org/wiki/Denmark',
    FI: 'https://en.wikipedia.org/wiki/Finland',
    FR: 'https://en.wikipedia.org/wiki/France',
    DE: 'https://en.wikipedia.org/wiki/Germany',
    IE: 'https://en.wikipedia.org/wiki/Ireland',
    IL: 'https://en.wikipedia.org/wiki/Israel',
    IT: 'https://en.wikipedia.org/wiki/Italy',
    NL: 'https://en.wikipedia.org/wiki/Netherlands',
    NZ: 'https://en.wikipedia.org/wiki/New_Zealand',
    NO: 'https://en.wikipedia.org/wiki/Norway',
    ES: 'https://en.wikipedia.org/wiki/Spain',
    SE: 'https://en.wikipedia.org/wiki/Sweden',
    TR: 'https://en.wikipedia.org/wiki/Turkey'
  };

  return sameAs[country];
};

// eslint-disable-next-line import/prefer-default-export
export const audienceNodes = (country) => {
  const sameAs = sameAsLookUp(country);

  // No need to define the country if it's GB as it's already defined in the base graph
  const isGB = country === 'GB';

  return [
    {
      '@type': 'Audience',
      '@id': `https://www.justpark.com/#/schema/Audience/${country}`,
      geographicArea: {
        '@id': `https://www.justpark.com/#/schema/Country/${country}`
      }
    },
    ...(isGB
      ? []
      : [
          {
            '@type': 'Country',
            '@id': `https://www.justpark.com/#/schema/Country/${country}`,
            name: country,
            ...(sameAs !== undefined ? { sameAs } : {})
          }
        ])
  ];
};
