// @ts-nocheck
import React from "react";
import OvergroundSvg from "../../../svg/overground.svg";
import "./overground.scss";

const Overground = () => {
  const baseClass = "c-overground";
  return <OvergroundSvg className={baseClass} />;
};
export default Overground;
