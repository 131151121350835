// @ts-nocheck
/**
 * Turn a string into kebab-case.
 *
 * Consider the variable named "parent error component"; representing this in
 * different cases gives:
 * Kebab case: parent-error-component
 * Camel case: parentErrorComponent
 * Pascal case: ParentErrorComponent
 * Snake case: parent_error_component
 * @param input
 * @returns {string}
 */
export default function kebabCase(input: string): string {
  return input
    .replace(/([a-z])([A-Z])/g, "$1-$2")
    .replace(/\s+/g, "-")
    .toLowerCase();
}
