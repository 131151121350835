// @ts-nocheck
import React from "react";
import bem from "@justpark/helpers/src/bem/bem";
import Info from "../../../svg/info.svg";
import InfoSecondary from "../../../svg/info_secondary.svg";
import "./infoIcon.scss";

type Props = {
  className?: string;
  type?:
    | "warning"
    | "info"
    | "secondary"
    | "blue"
    | "black"
    | "danger"
    | "brand";
  size?: "small" | "medium";
};
const InfoIcon = ({ className = "", type = "info", size = null }: Props) => {
  const baseClass = "c-info-icon";
  return type === "secondary" ? (
    <InfoSecondary
      className={bem(baseClass, null, {
        type
      })}
    />
  ) : (
    <Info
      className={`${bem(baseClass, null, {
        type,
        size
      })} ${className}`}
    />
  );
};

export default InfoIcon;
