import React, { useEffect } from 'react';
import InfoBox from '@justpark/ui/src/components/InfoBox';
import Typography from '@justpark/ui/src/components/Typography';
import type { CheckoutSummaryResult } from '../../generated/graphql';
import { gtagTrack } from '../../helpers/Tracking/Analytics';
import { ampli } from '../../helpers/Tracking/amplitude';
import ListingDetailsAvailabilityError from './ListingDetailsAvailabilityError';

type Props = {
  data: CheckoutSummaryResult;
  error: null | {
    message: string;
  };
};

const CheckoutSummary = ({ data, error }: Props) => {
  useEffect(() => {
    // Manually fire an api error event for this graphql error.
    // This doesn't make too much sense to do, we should probably track availability errors differently
    // in a way that is independent of the way the request was made.

    if (data?.__typename === 'AvailabilityError') {
      gtagTrack('api_error', {
        httpStatus: 422,
        errorCode: 5001,
        requestURL: '/v5/checkout/summary'
      });

      ampli.apiErrorOccurred({
        http_status_code: 422,
        error_code: 5001,
        request_url: '/v5/checkout/summary'
      });
    }
  }, [data]);

  if (data?.__typename === 'AvailabilityError') {
    return <ListingDetailsAvailabilityError />;
  }

  if (data?.__typename === 'ValidationError' && Array.isArray(data.meta)) {
    return (
      <InfoBox type="error" slim cypress="checkout-summary-error">
        {data.meta.map(({ messages }) =>
          messages.map((message) => (
            <Typography size="subtext">
              <div>{message}</div>
            </Typography>
          ))
        )}
      </InfoBox>
    );
  }

  const generalError = data?.message || error?.message;

  if (generalError) {
    return (
      <InfoBox border type="error" slim cypress="checkout-summary-error">
        <Typography size="secondary">
          <span>{generalError}</span>
        </Typography>
      </InfoBox>
    );
  }

  return null;
};

export default CheckoutSummary;
