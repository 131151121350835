// @ts-nocheck
import React from "react";
import DlrSvg from "../../../svg/dlr.svg";
import "./dlr.scss";

const Dlr = () => {
  const baseClass = "c-dlr";
  return <DlrSvg className={baseClass} />;
};
export default Dlr;
