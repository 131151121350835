import React from 'react';
import Typography from '@justpark/ui/src/components/Typography';
import FAQs from '@justpark/ui/src/components/FAQs';
import Heading from '@justpark/ui/src/components/Heading';
import { useTranslation } from 'react-i18next';

type Props = {
  canonical: string;
};

const ListingFAQs = ({ canonical }: Props) => {
  const { t } = useTranslation();

  const faqs = [
    {
      question: t('listingDetails:faqWorksQuestion', 'How does it work?'),
      answer: (
        <Typography>
          {t(
            'listingDetails:faqWorksAnswer',
            `JustPark has access to tens of thousands of spaces from car parks to
        private driveways that you can book in advance. Once you’ve found a
        space on our App or website, complete your booking and we’ll send
        through details of how to find and use your space.`
          )}
        </Typography>
      )
    },
    {
      question: t(
        'listingDetails:faqContactQuestion',
        'How can I contact you if I need help?'
      ),
      answer: (
        <Typography>
          {t(
            'listingDetails:faqContactAnswer',
            `We have a dedicated customer support team online 8-8 Mon-Fri and 9-6
          Sat-Sun. You can call or Live Chat with them during these hours. Contact
          details can be found on our Help Centre.`
          )}
        </Typography>
      )
    },
    {
      question: t('listingDetails:faqFindQuestion', 'How do I find my space?'),
      answer: (
        <Typography>
          {t(
            'listingDetails:faqFindAnswer',
            `When you have completed your booking and payment has been taken, we’ll
            send through the exact address and access instructions to your space.`
          )}
        </Typography>
      )
    },
    {
      question: t(
        'listingDetails:faqCancelQuestion',
        'Can I cancel my booking?'
      ),
      answer: (
        <Typography>
          {t(
            'listingDetails:faqCancelAnswer',
            `Yes we have a flexible cancellation policy, you can cancel up to 24hrs
            before the start time of your booking.`
          )}
        </Typography>
      )
    }
  ];

  return (
    <>
      <Heading level={2}>
        {t('listingDetails:faqHeader', 'Frequently asked questions')}
      </Heading>
      <FAQs faqs={faqs} canonical={canonical} />
    </>
  );
};

export default ListingFAQs;
