import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import AvailabilityError from '../AvailabilityError';
import DatesContext from './DatesContext';
import { selectPartnerName } from '../../stores/partner';
import AddressContext from './addressContext';

const ListingDetailsAvailabilityError = () => {
  const partnerName = useSelector(selectPartnerName);
  const {
    fixedStart,
    fixedEnd,
    monthlyStart,
    monthlyType,
    bookingTab: activeTab
  } = useContext(DatesContext);
  const address = useContext(AddressContext);

  const isMonthly = activeTab === 1;
  const startDate = isMonthly ? monthlyStart : fixedStart;
  const endDate = isMonthly ? null : fixedEnd;

  return (
    <AvailabilityError
      address={address}
      startDate={startDate}
      endDate={endDate}
      monthlyDuration={isMonthly ? '0' : null}
      partnerName={partnerName}
      hasEv={false}
      monthlyType={monthlyType}
      hasAvailabilityError
    />
  );
};

export default ListingDetailsAvailabilityError;
