// @ts-nocheck
import React, { Suspense, lazy } from "react";
import { Dayjs } from "dayjs";
import bem from "@justpark/helpers/src/bem/bem";
import LoadingWheel from "../LoadingWheel";
import "./lazySingleDatePicker.scss";

const SingleDatePicker = lazy(() => import("../SingleDatePicker/index"));
type Props = {
  date: Dayjs | string;
  onChange: (dayjs: any) => void;
  className?: string;
  maxDate?: string | null;
  minDate?: string | null;
};
/**
 * This component uses React.Lazy to lazy load the component only when it needs to be rendered
 * and displays a fallback LoadingWheel when it is loading
 *
 * In the tooltip / parent component, only render this component when the tooltip is enabled / being shown
 * for the lazy-loading to work correctly
 */
const baseClass = "c-lazy-single-date-picker";
const LazySingleDatePicker = ({
  date,
  onChange,
  className = "",
  minDate = null,
  maxDate = null
}: Props) => (
  <div className={baseClass}>
    <Suspense
      fallback={
        <div className={bem(baseClass, "loading")}>
          <LoadingWheel />
        </div>
      }
    >
      <SingleDatePicker
        className={className}
        date={date}
        onChange={onChange}
        minDate={minDate}
        maxDate={maxDate}
      />
    </Suspense>
  </div>
);
export default LazySingleDatePicker;
