import React from 'react';
import { Link } from '@not-remix-run/react';
import InfoBox from '@justpark/ui/src/components/InfoBox';
import InfoBoxInfoIcon from '@justpark/ui/src/components/Icons/infoBoxInfoIncon';
import convertToDayjs from '@justpark/helpers/src/dates/convertToDayjs';
import { Trans } from 'react-i18next';
import Typography from '@justpark/ui/src/components/Typography';
import type { Address } from '../../types';
import buildSearchUrl from '../helpers/buildSearchUrl';

type AvailabilityErrorProps = {
  address: Address;
  error?: string;
  startDate: string;
  endDate: string;
  monthlyDuration: string | undefined | null;
  partnerName: string | undefined | null;
  hasEv: boolean;
  monthlyType: string | undefined | null;
  hasAvailabilityError: boolean;
};

const AvailabilityError = ({
  error,
  address,
  startDate,
  endDate,
  monthlyDuration,
  partnerName,
  hasEv,
  monthlyType,
  hasAvailabilityError
}: AvailabilityErrorProps) => {
  if (!hasAvailabilityError && (!error || !error.length)) return null;

  const startDateInstance = convertToDayjs(startDate);
  const endDateInstance = endDate ? convertToDayjs(endDate) : null;

  return (
    <InfoBox icon={<InfoBoxInfoIcon />} type="blue-bright" border>
      <span data-cy="availability-error-nearby">
        {hasAvailabilityError && (
          <Trans i18nKey="checkout:availabilityErrorNearbySpaces">
            The space and time is not available for those dates. Please try
            another time or{' '}
            <Link
              to={buildSearchUrl(
                address,
                startDateInstance,
                endDateInstance,
                monthlyDuration,
                partnerName,
                hasEv,
                monthlyType
              )}
              data-cy="availability-error-nearby-link"
            >
              <Typography size="secondary" decoration="underline" bold>
                <div>check nearby spaces</div>
              </Typography>
            </Link>
          </Trans>
        )}

        {!hasAvailabilityError && error}
      </span>
    </InfoBox>
  );
};

export default AvailabilityError;
