// @ts-nocheck
import React from "react";
import styles from "./minimalReviewStars.module.scss";
import MinimalStar from "../qicons/MinimalStar";

type Props = {
  rating: number;
};
const MinimalReviewStars = ({ rating }: Props) => {
  const floor = Math.floor(rating);
  const roundUp = rating - floor > 0.75 ? 1 : 0;
  const wholeStars = floor + roundUp;
  const halfStar = roundUp === 0 && rating > floor ? 1 : 0;
  const emptyStars = 5 - wholeStars - halfStar;
  return (
    <div className={styles.base}>
      {[...Array(wholeStars)].map((value, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <MinimalStar size="1x" key={index} />
      ))}
      {[...Array(halfStar)].map((value, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <MinimalStar size="1x" modifier="half" key={index} />
      ))}
      {[...Array(emptyStars)].map((value, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <MinimalStar size="1x" modifier="empty" key={index} />
      ))}
    </div>
  );
};
export default MinimalReviewStars;
