import type { Dayjs } from "dayjs";

// Never use for values passed to api
const formatDateForUserDisplay = (
  date: Dayjs,
  dateFormatKey: string,
  dateFormatDefault: string,
  t: (key: string, defaultString: string) => string
) => date.format(t(dateFormatKey, dateFormatDefault));

export default formatDateForUserDisplay;
