import React from 'react';
import Typography from '@justpark/ui/src/components/Typography';
import { useTranslation } from 'react-i18next';

import styles from '../../../static/scss/components/listingDetails/adminBanner.module.scss';

const AdminBanner = ({
  listingId,
  userId
}: {
  listingId: number;
  userId: number;
}) => {
  const { t } = useTranslation();
  return (
    <div className={styles.adminBanner} data-cy="admin-banner">
      <Typography className={styles.content}>
        <span className={styles.heading}>
          {t('listingDetails:listingId', 'Listing ID:')}
        </span>
        <span className={styles.listingId} data-cy="admin-banner-listingId">
          {listingId}
        </span>
        |
        <span className={`${styles.heading} ${styles.userId}`}>
          {t('listingDetails:userId', 'User ID:')}
        </span>
        <span data-cy="admin-banner-userId">{userId}</span>
      </Typography>
    </div>
  );
};

export default AdminBanner;
