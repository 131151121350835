export const IS_SHELTERED = 'is_sheltered';
export const HAS_CCTV = 'has_cctv';
export const IS_GATED = 'is_gated';
export const HAS_DISABLED_ACCESS = 'has_disabled_access';
export const HAS_TRANSFERS = 'has_transfers';
export const HAS_LIGHTING = 'has_lighting';
export const HAS_EV_CHARGING = 'has_ev_charging';

const facilities = (t = (key: string, value: string) => value) => [
  {
    value: IS_GATED,
    label: t('searchResultsFilters:securelyGated', 'Securely gated'),
    description: t(
      'searchResultsFilters:securelyGatedDescription',
      'Enclosed within walls and/or locked'
    )
  },
  {
    value: HAS_CCTV,
    label: t('searchResultsFilters:cctv', 'CCTV'),
    description: t(
      'searchResultsFilters:cctvDescription',
      'Video surveillance of your space'
    )
  },
  {
    value: HAS_DISABLED_ACCESS,
    label: t('searchResultsFilters:disabledAccess', 'Disabled access'),
    description: t(
      'searchResultsFilters:disabledAccessDescription',
      'Parking has specialist access'
    )
  },
  {
    value: HAS_LIGHTING,
    label: t('searchResultsFilters:lighting', 'Lighting'),
    description: t(
      'searchResultsFilters:lightingDescription',
      'Greater visibility for your parking space'
    )
  },
  {
    value: IS_SHELTERED,
    label: t('searchResultsFilters:coveredParking', 'Covered parking'),
    description: t(
      'searchResultsFilters:coveredParkingDescription',
      'Full/partial roof to shield vehicles parked'
    )
  },
  {
    value: HAS_TRANSFERS,
    label: t('searchResultsFilters:airportTransfers', 'Airport transfers'),
    description: t(
      'searchResultsFilters:airportTransfersDescription',
      'Easy transfers to airport by space owner'
    )
  },
  {
    value: HAS_EV_CHARGING,
    label: t(
      'searchResultsFilters:vehicleCharging',
      'Electric vehicle charging'
    ),
    description: t(
      'searchResultsFilters:vehicleChargingDescription',
      'Access to electric vehicle charging'
    )
  }
];

export default facilities;
