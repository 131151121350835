// @ts-nocheck
import React from "react";
import AirplaneSvg from "../../../svg/airplane.svg";
import "./airplane.scss";

const Airplane = () => {
  const baseClass = "c-airplane";
  return <AirplaneSvg className={baseClass} />;
};
export default Airplane;
