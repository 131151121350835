import React from 'react';
import Heading from '@justpark/ui/src/components/Heading';
import { useTranslation } from 'react-i18next';
import ListingInformation from '../ListingInformation';
import type { Listing } from '../../generated/graphql';

import styles from '../../../static/scss/components/listingDetails/information.module.scss';

type Props = {
  location: Listing;
};

const Information = ({ location }: Props) => {
  const { t } = useTranslation();

  return (
    <div className={styles.informationWrapper}>
      <Heading level={2}>
        {t('listingDetails:descriptionHeader', 'Space description')}
      </Heading>

      <ListingInformation
        location={location}
        className={styles.listingInformation}
      />
    </div>
  );
};

export default Information;
